#overview.company
				
	.company01
		._desc
			margin-bottom: 50px
			+mq()
				margin-bottom: px2vwSP(30)
	.company02
		._desc
			margin-bottom: 50px
			+mq()
				margin-bottom: px2vwSP(30)
		._dl
			margin-bottom: 50px
			+mq()
				margin-bottom: px2vwSP(30)
