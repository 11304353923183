@charset "UTF-8";
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border: 0;
  outline: 0;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main,
details,
hgroup,
menu {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

ul,
ol,
li,
menu,
dir,
figure {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  margin-block: 0 0;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  padding: 0;
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace;
  font-size: 1em;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  line-height: 0;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
  appearance: button;
}

[hidden],
template {
  display: none;
}

input[type=button],
input[type=text],
input[type=search],
input[type=submit],
input[type=image],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

table,
tbody {
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  border: 0;
}

ul li {
  list-style: none;
}

html {
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
}

body {
  position: relative;
  overflow-x: hidden;
  color: #000;
  background: #fff;
  -webkit-font-smoothing: antialiased;
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
}

.body {
  position: relative;
}

.main {
  background: #C0E4F2;
}

.header__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1269px;
  height: 80px;
  padding: 0 12px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .header__top {
    position: relative;
    z-index: 10;
    height: 70px;
    padding: 0 60px 0 12px;
    background: #F5FAFD;
    -webkit-box-shadow: 0 2px 2px rgba(203, 203, 203, 0.75);
    box-shadow: 0 2px 2px rgba(203, 203, 203, 0.75);
  }
}
.header__title {
  display: block;
  width: 530px;
  margin: 10px 0 8px;
}
@media screen and (max-width: 1180px) {
  .header__title {
    width: 205px;
    margin: 23.5px 0;
  }
}
@media screen and (width <= 900px) {
  .header__title {
    margin: 16px 0;
  }
}
.header__logo {
  line-height: 0;
}
.header__top-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
}
.header__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  margin-top: 27px;
}
@media screen and (width <= 900px) {
  .header__menu {
    display: none;
  }
}
.header__menu-list {
  width: 115px;
}
.header__menu-list:nth-of-type(1) {
  width: 180px;
}
.header__menu-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  color: #5A3F28;
  text-indent: -1px;
  letter-spacing: 1px;
  background: #C6E3DA;
}
.header__menu-arrow {
  display: block;
  padding-right: 5px;
  font-size: 5px;
  color: #4DA68B;
}
.header__access {
  display: block;
  margin-top: 18px;
  font-size: 16px;
  font-weight: 500;
  color: #5A3F28;
  text-align: center;
}
@media screen and (width <= 900px) {
  .header__access {
    margin-top: 23px;
    font-size: 10px;
    letter-spacing: 0.2px;
  }
}
.header__access-img {
  width: 22px;
}
@media screen and (width <= 900px) {
  .header__access-img {
    width: 11px;
  }
}
@media screen and (width <= 900px) {
  .header__access-text {
    margin-top: 5px;
  }
}
.header__bottom {
  background: #5A3F28;
}
@media screen and (width <= 900px) {
  .header__bottom {
    display: none;
  }
}
.header__navul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 1300px;
  padding: 10px 0;
  margin: 0 auto;
}
.header__navli {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  line-height: 18px;
}
.header__navli::after {
  display: block;
  content: "";
  border-right: 1px solid #fff;
}
.header__navli:last-of-type::after {
  content: none;
}
.header__navlink {
  margin: 0 2em;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 2px;
}

.sp-nav__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 2s;
  transition: 2s;
}
.sp-nav__wrapper.scroll, .sp-nav__wrapper.open {
  -webkit-box-shadow: 0 2px 2px 2px rgba(203, 203, 203, 0.5);
  box-shadow: 0 2px 2px 2px rgba(203, 203, 203, 0.5);
}
.sp-nav__line {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  display: none;
  width: 60px;
  height: 70px;
  padding-right: 5px;
  cursor: pointer;
  background: #F5FAFD;
}
@media screen and (width <= 900px) {
  .sp-nav__line {
    display: block;
  }
}
.sp-nav__line.scroll, .sp-nav__line.open {
  position: static;
  width: 65px;
  padding-left: 5px;
}
.sp-nav__in {
  width: 20px;
  padding: 22px 0;
  margin: 0 auto;
}
.sp-nav__border1, .sp-nav__border2, .sp-nav__border3 {
  display: block;
  width: 20px;
  height: 2px;
  margin: 5px 0;
  background-color: #5a3f28;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sp-nav__border1.open {
  width: 26px;
  -webkit-transform: rotate(39deg) translate(2px, 7px);
  transform: rotate(39deg) translate(2px, 7px);
}
.sp-nav__border2.open {
  opacity: 0;
}
.sp-nav__border3.open {
  width: 26px;
  -webkit-transform: rotate(-39deg) translate(2px, -7px);
  transform: rotate(-39deg) translate(2px, -7px);
}
.sp-nav__icon {
  display: none;
  gap: 7px;
  width: 248px;
  height: 70px;
  padding: 16px 15px;
  background: #D4EAE4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-75px);
  transform: translateY(-75px);
}
@media screen and (width <= 900px) {
  .sp-nav__icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.sp-nav__icon.scroll, .sp-nav__icon.open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.sp-nav__icon-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 38px;
  height: 38px;
}
.sp-nav__icon-link_line {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.48px;
  background: #00B900;
}
.sp-nav__main {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  background: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(105vw);
  transform: translateX(105vw);
}
.sp-nav__main.open {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.sp-nav__box {
  padding-top: 70px;
  background: #C0E4F1;
}
.sp-nav__only {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 51px;
  padding: 0 0 0 20px;
  font-size: 15px;
  font-weight: 500;
  color: #5A3F28;
  letter-spacing: 1.5px;
  cursor: pointer;
  background: #C0E4F1;
  border-bottom: 1px solid #fff;
}
.sp-nav__only::after {
  position: absolute;
  top: 50%;
  right: 10px;
  display: block;
  color: #34997B;
  content: "〉";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sp-nav__parent {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 51px;
  padding: 0 0 0 20px;
  font-size: 15px;
  font-weight: 500;
  color: #5A3F28;
  letter-spacing: 1.5px;
  cursor: pointer;
  background: #C0E4F1;
  border-bottom: 1px solid #fff;
}
.sp-nav__parent::after, .sp-nav__parent::before {
  position: absolute;
  top: 50%;
  display: block;
  content: "";
  background: #34997B;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sp-nav__parent::after {
  right: 24px;
  width: 1px;
  height: 8px;
}
.sp-nav__parent::before {
  right: 20px;
  width: 8px;
  height: 1px;
}
.sp-nav__parent.open::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sp-nav__parent.open::before {
  opacity: 0;
}
.sp-nav__child {
  display: none;
  padding: 16px 45px;
  background: #fff;
}
.sp-nav__childul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.sp-nav__childli {
  display: block;
  width: 140px;
}
.sp-nav__child-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  line-height: 34px;
  color: #5A3F28;
  letter-spacing: 1.5px;
}
.sp-nav__child-link::before {
  display: block;
  font-size: 12px;
  color: #34997B;
  content: "〉";
}
.sp-nav__menu-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  margin-bottom: 1px;
  font-size: 13px;
  color: #5A3F28;
  letter-spacing: 1.56px;
  background: #DFF0F4;
}
.sp-nav__menu-arrow {
  margin-right: 7px;
  font-size: 10px;
  color: #34997B;
}
.sp-nav__foot {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 26px;
}
.sp-nav__foot:last-of-type {
  margin-top: 0;
}
.sp-nav__footli {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
}
.sp-nav__footli::after {
  display: block;
  width: 1px;
  height: 12px;
  content: "";
  background: #5A3F28;
}
.sp-nav__footli:last-of-type::after {
  content: none;
}
.sp-nav__foot-link {
  display: block;
  padding: 0 1em;
  font-size: 12px;
  line-height: 24px;
  color: #5A3F28;
  letter-spacing: 1.92px;
}

.aside__nav {
  position: fixed;
  top: 125px;
  right: 0;
  z-index: 1000;
  display: block;
}
@media screen and (width <= 900px) {
  .aside__nav {
    display: none;
  }
}
.aside__navli {
  display: block;
  margin-bottom: 4px;
}
.aside__navli:nth-of-type(1) * {
  height: 150px;
  background: #E95504;
}
.aside__navli:nth-of-type(2) * {
  height: 150px;
  background: #009EA7;
}
.aside__navli:nth-of-type(3) * {
  height: 150px;
  background: #725C48;
}
.aside__navli:nth-of-type(4) *, .aside__navli:nth-of-type(5) * {
  height: 60px;
  background: transparent;
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
}
.aside__navlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  letter-spacing: -0.35px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.aside__navimg {
  width: 60px;
}
.aside__sp {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: none;
  width: 100%;
  height: 70px;
  overflow: auto hidden;
  background: #fff;
  -webkit-box-shadow: 0 -1px 2px rgba(220, 220, 220, 0.5);
  box-shadow: 0 -1px 2px rgba(220, 220, 220, 0.5);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(75px);
  transform: translateY(75px);
}
@media screen and (width <= 900px) {
  .aside__sp {
    display: block;
  }
}
.aside__sp.scroll {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.aside__spul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 14px;
}
.aside__spli {
  display: block;
}
.aside__spli:nth-of-type(1) * {
  background: #E95504;
}
.aside__spli:nth-of-type(2) * {
  background: #009EA7;
}
.aside__spli:nth-of-type(3) * {
  background: #5A3F28;
}
.aside__splink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 115px;
  height: 42px;
  font-size: 11px;
  line-height: 12.82px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.39px;
}

.footer {
  padding-top: 100px;
  border-top: 3px solid #5A3F28;
}
@media screen and (width <= 900px) {
  .footer {
    padding-top: 60px;
    border-top: 2px solid #5A3F28;
  }
}
@media screen and (width <= 900px) {
  .footer {
    margin-bottom: 70px;
  }
}
.footer__iconborder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .footer__iconborder {
    display: block;
  }
}
.footer__iconborder::before, .footer__iconborder::after {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 280px;
  height: 1px;
  content: "";
  background: #008842;
}
@media screen and (width <= 900px) {
  .footer__iconborder::before, .footer__iconborder::after {
    content: none;
  }
}
.footer__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 340px;
}
@media screen and (width <= 900px) {
  .footer__icon {
    width: 290px;
    margin: 0 auto;
  }
}
.footer__icon-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 19px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.76px;
}
@media screen and (width <= 900px) {
  .footer__icon-link {
    width: 40px;
    height: 40px;
    font-size: 16px;
    letter-spacing: -0.64px;
  }
}
.footer__icon-link_line {
  background: #00B900;
}
.footer__menu0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 28px;
  max-width: 1044px;
  padding: 55px 24px 45px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .footer__menu0 {
    display: none;
  }
}
.footer__parent, .footer__only {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 4px;
  font-size: 17px;
  font-weight: 500;
  line-height: 42px;
  color: #5A3F28;
  letter-spacing: 1.7px;
}
.footer__parent::before, .footer__only::before {
  display: block;
  color: #34997B;
  content: "・";
}
.footer__only {
  margin-top: 10px;
}
.footer__childli {
  margin-left: 22px;
}
.footer__child-link, .footer__child-link2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 0;
  font-size: 15px;
  line-height: 22px;
  color: #5A3F28;
  letter-spacing: 1.5px;
}
.footer__child-link::before {
  display: block;
  color: #34997B;
  content: "〉";
}
.footer__child-link2 {
  position: relative;
  padding-left: 12px;
  margin-left: 24px;
}
.footer__agl {
  position: absolute;
  top: 7px;
  left: 0;
  display: block;
  width: 9px;
}
.footer__menu2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 1044px;
  padding: 0 24px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .footer__menu2 {
    display: none;
  }
}
.footer__menu2-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  font-size: 15px;
  color: #5A3F28;
  letter-spacing: 1.8px;
  background: #C6E3DA;
}
.footer__menu2-arrow {
  margin-right: 2px;
  font-size: 10px;
  color: #34997B;
}
.footer__m3s {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 45px;
}
@media screen and (width <= 900px) {
  .footer__m3s {
    display: block;
    margin-top: 40px;
  }
}
.footer__menu3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.footer__menu3-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 22px;
}
@media screen and (width <= 900px) {
  .footer__menu3-list {
    height: 29px;
  }
}
.footer__menu3-list::after {
  display: block;
  width: 1px;
  height: 14px;
  content: "";
  background: #5A3F28;
}
@media screen and (width <= 900px) {
  .footer__menu3-list:last-of-type::after {
    content: none;
  }
}
.footer__menu3-listlast {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 22px;
}
@media screen and (width <= 900px) {
  .footer__menu3-listlast {
    height: 29px;
  }
}
.footer__menu3-link {
  display: block;
  padding: 0 1em;
  font-size: 14px;
  line-height: 22px;
  color: #5A3F28;
  letter-spacing: 1.68px;
}
@media screen and (width <= 900px) {
  .footer__menu3-link {
    font-size: 13px;
    line-height: 29px;
    letter-spacing: 1.56px;
  }
}
.footer__box {
  background: #EEECEA;
}
.footer__boxin {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 62px;
  max-width: 1050px;
  padding: 40px 25px;
  margin: 75px auto 0;
}
@media screen and (width <= 900px) {
  .footer__boxin {
    display: block;
    padding: 35px 25px 30px;
    margin: 40px auto 0;
  }
}
.footer__boxlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 170px;
  height: 84px;
  font-size: 18px;
  font-weight: 500;
  color: #5A3F28;
  letter-spacing: 1.8px;
  border: 1px solid #5A3F28;
}
@media screen and (width <= 900px) {
  .footer__boxlink {
    width: 290px;
    height: 35px;
    margin: 0 auto;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
}
.footer__flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 62px;
}
@media screen and (max-width: 1000px) {
  .footer__flex {
    width: 500px;
  }
}
@media screen and (width <= 900px) {
  .footer__flex {
    display: block;
    width: 290px;
    margin: 21px auto 0;
  }
}
.footer__menu4, .footer__menu5, .footer__menu6 {
  display: block;
}
@media screen and (width <= 900px) {
  .footer__menu5-list:nth-of-type(3) {
    padding-bottom: 8px;
  }
}
.footer__menu4-link {
  margin-bottom: 10px;
}
@media screen and (width <= 900px) {
  .footer__menu4-link {
    margin: 0;
  }
}
.footer__menu5-link, .footer__menu6-link {
  margin-bottom: 5px;
}
@media screen and (width <= 900px) {
  .footer__menu5-link, .footer__menu6-link {
    margin: 0;
  }
}
.footer__menu4-link, .footer__menu5-link, .footer__menu6-link {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #5A3F28;
  letter-spacing: 1.6px;
}
.footer__menu4-link::before, .footer__menu5-link::before, .footer__menu6-link::before {
  content: "〉";
}
@media screen and (width <= 900px) {
  .footer__menu4-link, .footer__menu5-link, .footer__menu6-link {
    padding: 6px 0;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
}
.footer__menu5-link-ss, .footer__menu6-link-ss {
  display: block;
  margin-left: 25px;
  font-size: 14px;
  line-height: 20px;
  color: #5A3F28;
  letter-spacing: 1.12px;
}
@media screen and (width <= 900px) {
  .footer__menu5-link-ss, .footer__menu6-link-ss {
    font-size: 13px;
    letter-spacing: 1.04px;
  }
}
.footer__angle {
  display: inline-block;
  width: 9px;
  margin-bottom: 4px;
}
.footer__dot {
  display: none;
}
@media screen and (width <= 900px) {
  .footer__dot {
    display: block;
    height: 3px;
    line-height: 0;
  }
}
.footer__linedot {
  display: block;
  width: 290px;
  max-width: none;
  height: 3px;
  -o-object-fit: cover;
  object-fit: cover;
}
.footer__copyright {
  padding: 45px;
  background: #008842;
}
@media screen and (width <= 900px) {
  .footer__copyright {
    padding: 26px 0;
  }
}
.footer__cptext {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  letter-spacing: 1.2px;
}
@media screen and (width <= 900px) {
  .footer__cptext {
    font-size: 8px;
    letter-spacing: 0.96px;
  }
}

.pagetop {
  position: relative;
}
.pagetop__link {
  position: absolute;
  right: 80px;
  bottom: 55px;
  z-index: 20;
  width: 80px;
}
@media screen and (width <= 900px) {
  .pagetop__link {
    right: 24px;
    bottom: 40px;
    width: 50px;
  }
}

.top-mainvisual {
  position: relative;
  padding-top: 110px;
}
@media screen and (max-width: 1300px) {
  .top-mainvisual {
    padding-top: 8.4615384615vw;
  }
}
@media screen and (width <= 900px) {
  .top-mainvisual {
    padding-top: 67px;
  }
}
.top-mainvisual__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: block;
  max-width: 1300px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .top-mainvisual__bg {
    max-width: 375px;
  }
}
.top-mainvisual__main {
  position: relative;
  z-index: 10;
  max-width: 581px;
  margin: 0 auto;
}
@media screen and (max-width: 1300px) {
  .top-mainvisual__main {
    width: 44.6923076923vw;
  }
}
@media screen and (width <= 900px) {
  .top-mainvisual__main {
    width: 290px;
  }
}

.top-news {
  position: relative;
  z-index: 10;
  max-width: 888px;
  padding: 0 14px;
  margin: 64px auto 0;
}
.top-news__line {
  display: block;
  max-width: 860px;
  height: 3px;
  line-height: 0;
}
.top-news__lineimg {
  width: 857px;
  max-width: none;
  height: 3px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media screen and (width <= 900px) {
  .top-news__lineimg {
    width: calc(100vw - 28px);
  }
}
.top-news__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  margin: 25px 0;
}
@media screen and (width <= 900px) {
  .top-news__wrapper {
    display: block;
    margin: 18px 18px 40px;
  }
}
.top-news__icon {
  display: block;
  width: 138px;
}
@media screen and (width <= 900px) {
  .top-news__icon {
    width: 146px;
    margin: 0 auto;
  }
}
.top-news__box {
  display: block;
  margin-top: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #5A3F28;
  letter-spacing: 1.92px;
}
.top-news__box:last-of-type {
  margin-bottom: 0;
}
@media screen and (width <= 900px) {
  .top-news__box:last-of-type {
    display: none;
  }
}
.top-news__date {
  color: #8B7869;
}
.top-news__show {
  position: absolute;
  right: 14px;
  bottom: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #5A3F28;
}
@media screen and (width <= 900px) {
  .top-news__show {
    right: 32px;
    bottom: 15px;
  }
}
.top-news__arrow {
  display: block;
  width: 10px;
  margin-left: 10px;
  font-size: 10px;
  color: #008842;
}

.top-box0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 638px;
  padding: 0 14px;
  margin: 85px auto 0;
}
.top-box0__btn {
  display: block;
  width: 290px;
}
@media screen and (width <= 900px) {
  .top-box0__btn {
    width: 238px;
  }
}

.top-movie {
  position: relative;
}
.top-movie__bg {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  margin: 0 auto;
}
.top-movie__bgimg {
  position: relative;
  left: 50%;
  display: block;
  width: 100%;
  max-width: 2559px;
  height: 461px;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -o-object-fit: none;
  object-fit: none;
}
@media screen and (max-width: 700px) {
  .top-movie__bgimg {
    right: 0;
    left: 0;
    height: auto;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -o-object-fit: fill;
    object-fit: fill;
  }
}
.top-movie__wrapper {
  position: relative;
  z-index: 20;
  padding: 90px 0 75px;
}
@media screen and (width <= 900px) {
  .top-movie__wrapper {
    padding: 52px 0 23px;
  }
}
.top-movie__title {
  width: 322px;
  margin: 0 auto;
}
@media screen and (max-width: 700px) {
  .top-movie__title {
    width: 210px;
  }
}
.top-movie__btn {
  display: block;
  width: 606px;
  padding: 0;
  margin: 32px auto 0;
  cursor: pointer;
  background: transparent;
  border: none;
}
@media screen and (max-width: 700px) {
  .top-movie__btn {
    width: 227px;
    margin-top: 23px;
  }
}
.top-movie__modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.top-movie__modal.open {
  display: block;
}
.top-movie__youtube {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 800px;
  height: 100%;
  padding: 25px;
  margin: 0 auto;
}
.top-movie__youtube-wrap {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9のアスペクト比を保つためのパディング */
}
.top-movie__youtube-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.top-fe__outer {
  padding: 90px 15px 0;
}
@media screen and (width <= 900px) {
  .top-fe__outer {
    padding-top: 50px;
  }
}
.top-fe__wrapper {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
}
.top-fe__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: block;
}
.top-fe__bgimg {
  height: 230px;
}
@media screen and (width <= 900px) {
  .top-fe__bgimg {
    height: 100px;
  }
}
.top-fe__title {
  position: relative;
  z-index: 5;
  width: 169px;
  padding-top: 90px;
  margin: 0 auto 50px;
}
@media screen and (width <= 900px) {
  .top-fe__title {
    width: 126px;
    height: 95px;
    padding-top: 50px;
    margin-bottom: 0;
  }
}
.top-fe__white {
  padding-bottom: 60px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
@media screen and (width <= 900px) {
  .top-fe__white {
    padding-bottom: 50px;
    border-radius: 0 0 3px 3px;
  }
}
.top-fe__boxouter1 {
  position: relative;
  max-width: 966px;
  padding-top: 14px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__boxouter1 {
    max-width: 345px;
    padding-top: 28px;
  }
}
.top-fe__bgbg1 {
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 5;
  width: 220px;
}
@media screen and (width <= 900px) {
  .top-fe__bgbg1 {
    top: 0;
    width: 98px;
  }
}
.top-fe__box1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 770px;
  padding: 56px 0 0;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__box1 {
    padding: 0 0 0 32px;
  }
}
.top-fe__col1 {
  padding-right: 5px;
}
@media screen and (width <= 900px) {
  .top-fe__col1 {
    padding: 47px 20px 0 0;
  }
}
.top-fe__point1 {
  position: relative;
  z-index: 5;
  width: 205px;
  margin-bottom: 25px;
}
@media screen and (width <= 900px) {
  .top-fe__point1 {
    width: 150px;
  }
}
.top-fe__p1-imgflex {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  padding-bottom: 72px;
}
@media screen and (width <= 900px) {
  .top-fe__p1-imgflex {
    display: block;
    padding-bottom: 50px;
  }
}
.top-fe__p1-img {
  display: block;
  width: 270px;
}
@media screen and (width <= 900px) {
  .top-fe__p1-img {
    width: 125px;
  }
}
.top-fe__p1-img_no2 {
  margin-top: 5px;
}
.top-fe__deco1 {
  position: absolute;
  right: 120px;
  bottom: 0;
  z-index: 10;
  display: block;
  width: 366px;
}
@media screen and (width <= 900px) {
  .top-fe__deco1 {
    right: 4px;
    width: 154px;
  }
}
.top-fe__boxouter2 {
  position: relative;
  max-width: 950px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__boxouter2 {
    max-width: 345px;
    margin: 30px auto 0;
  }
}
.top-fe__bgbg2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 220px;
}
@media screen and (width <= 900px) {
  .top-fe__bgbg2 {
    top: 18px;
    right: 5px;
    width: 103px;
  }
}
.top-fe__box2 {
  position: relative;
  max-width: 770px;
  padding: 22px 0 0;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__box2 {
    max-width: 320px;
    padding: 0;
  }
}
.top-fe__col2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 100px;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
  padding-top: 40px;
}
@media screen and (width <= 900px) {
  .top-fe__col2 {
    display: block;
    max-width: 289px;
    padding-top: 70px;
    margin: 0 auto;
  }
}
.top-fe__deco2 {
  display: block;
  width: 200px;
}
@media screen and (width <= 900px) {
  .top-fe__deco2 {
    position: absolute;
    top: 0;
    left: 35px;
    width: 110px;
  }
}
.top-fe__point2 {
  display: block;
  width: 410px;
}
@media screen and (width <= 900px) {
  .top-fe__point2 {
    width: 281px;
  }
}
.top-fe__imgflex2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  padding-top: 20px;
}
@media screen and (width <= 900px) {
  .top-fe__imgflex2 {
    padding-top: 10px;
  }
}
.top-fe__btn2 {
  margin: 22px 0 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__btn2 {
    margin-top: 15px;
  }
}
.top-fe__34outer {
  max-width: 938px;
  padding-top: 30px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__34outer {
    max-width: 345px;
    padding-top: 2px;
  }
}
.top-fe__34 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 814px;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  .top-fe__34 {
    padding-left: 7.7066666667vw;
  }
}
@media screen and (width <= 900px) {
  .top-fe__34 {
    display: block;
    padding-left: 0;
  }
}
.top-fe__box3 {
  position: relative;
  width: 340px;
  margin-right: 32px;
}
@media screen and (max-width: 1030px) {
  .top-fe__box3 {
    margin-right: 0.2222222222vw;
  }
}
.top-fe__bgbg3 {
  position: absolute;
  top: 0;
  left: -85px;
  z-index: 5;
  width: 150px;
}
@media screen and (max-width: 1000px) {
  .top-fe__bgbg3 {
    left: -5.7894736842vw;
  }
}
@media screen and (width <= 900px) {
  .top-fe__bgbg3 {
    left: 0;
    width: 65px;
  }
}
.top-fe__point3 {
  width: 240px;
  padding: 80px 0 0 18px;
}
@media screen and (width <= 900px) {
  .top-fe__point3 {
    padding: 40px 0 0;
    margin-left: 33px;
  }
}
@media screen and (width <= 900px) {
  .top-fe__p3-sp {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: end;
    -ms-flex-align: end;
    align-items: end;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 20px;
  }
}
.top-fe__p3img-outer {
  position: relative;
  padding: 42px 98px 23px 0;
}
@media screen and (width <= 900px) {
  .top-fe__p3img-outer {
    position: static;
    padding: 0;
  }
}
.top-fe__btn3, .top-fe__btn4 {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (width <= 900px) {
  .top-fe__btn3, .top-fe__btn4 {
    left: 0;
    width: 103px;
    margin: 0 auto;
  }
}
@media screen and (width <= 900px) {
  .top-fe__btn4 {
    bottom: 24px;
  }
}
.top-fe__p3-img {
  display: block;
  width: 240px;
}
@media screen and (width <= 900px) {
  .top-fe__p3-img {
    width: 125px;
    margin: 0 0 0 30px;
  }
}
.top-fe__deco3 {
  display: block;
  width: 262px;
  padding: 5px 0 0 5px;
}
@media screen and (width <= 900px) {
  .top-fe__deco3 {
    width: 135px;
    padding: 0;
    margin-right: 20px;
  }
}
.top-fe__box4 {
  position: relative;
  width: 441px;
  padding-left: 94px;
}
@media screen and (max-width: 1000px) {
  .top-fe__box4 {
    width: calc(347px + 9.4vw);
    padding-left: 9.4vw;
  }
}
@media screen and (width <= 900px) {
  .top-fe__box4 {
    width: 345px;
    padding: 0;
    margin: 45px auto 0;
  }
}
.top-fe__bgbg4 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 206px;
}
@media screen and (width <= 900px) {
  .top-fe__bgbg4 {
    right: 15px;
    left: auto;
    width: 59px;
  }
}
.top-fe__point4 {
  width: 265px;
  padding: 80px 0 0 18px;
}
@media screen and (width <= 900px) {
  .top-fe__point4 {
    width: 262px;
    padding: 15px 0 0;
    margin-left: 30px;
  }
}
@media screen and (width <= 900px) {
  .top-fe__p4-sp {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 20px;
  }
}
.top-fe__p4img-outer {
  position: relative;
  padding: 11px 98px 23px 0;
}
@media screen and (width <= 900px) {
  .top-fe__p4img-outer {
    position: static;
    padding: 0;
  }
}
.top-fe__p4-img {
  display: block;
  width: 240px;
}
@media screen and (width <= 900px) {
  .top-fe__p4-img {
    width: 125px;
    margin: 0 0 0 30px;
  }
}
.top-fe__deco4 {
  display: block;
  width: 299px;
  padding: 27px 0 0 25px;
}
@media screen and (width <= 900px) {
  .top-fe__deco4 {
    width: 148px;
    padding: 0;
    margin-top: 33px;
  }
}
.top-fe__boxouter5 {
  position: relative;
  max-width: 990px;
  margin: 50px auto 0;
}
@media screen and (width <= 900px) {
  .top-fe__boxouter5 {
    max-width: 345px;
    margin-top: 10px;
  }
}
.top-fe__bgbg5 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 220px;
}
@media screen and (width <= 900px) {
  .top-fe__bgbg5 {
    left: 2px;
    width: 98px;
  }
}
.top-fe__box5 {
  position: relative;
  max-width: 810px;
  padding: 95px 0 10px;
  margin: 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__box5 {
    padding: 45px 0 0;
  }
}
.top-fe__point5 {
  width: 693px;
}
@media screen and (width <= 900px) {
  .top-fe__point5 {
    width: 300px;
    margin: 0 0 0 30px;
  }
}
.top-fe__imgflex5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
}
@media screen and (width <= 900px) {
  .top-fe__imgflex5 {
    gap: 0;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 15px 15px 0;
  }
}
.top-fe__col5 {
  width: 379px;
  padding-top: 45px;
}
@media screen and (width <= 900px) {
  .top-fe__col5 {
    width: 150px;
    padding-top: 15px;
  }
}
.top-fe__col51 {
  width: 395px;
}
@media screen and (width <= 900px) {
  .top-fe__col51 {
    width: 150px;
  }
}
.top-fe__p5-img {
  display: block;
  width: 345px;
  margin: 0 0 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__p5-img {
    width: 138px;
  }
}
.top-fe__deco5 {
  display: block;
  width: 234px;
  padding-top: 5px;
}
@media screen and (width <= 900px) {
  .top-fe__deco5 {
    width: 105px;
  }
}
.top-fe__deco51 {
  display: block;
  width: 189px;
  margin: 0 0 0 auto;
}
@media screen and (width <= 900px) {
  .top-fe__deco51 {
    width: 83px;
  }
}
.top-fe__p51-img {
  display: block;
  width: 345px;
  padding-top: 5px;
}
@media screen and (width <= 900px) {
  .top-fe__p51-img {
    width: 138px;
  }
}
.top-fe__btn5 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
}
@media screen and (width <= 900px) {
  .top-fe__btn5 {
    position: static;
    margin: 0 15px;
  }
}
@media screen and (width <= 900px) {
  .top-fe__btn5-in {
    margin: 0 0 0 auto;
  }
}

.top-morebtn {
  position: relative;
  z-index: 5;
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 140px;
  height: 40px;
}
@media screen and (width <= 900px) {
  .top-morebtn {
    width: 103px;
    height: 30px;
  }
}
.top-morebtn::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(92.8571428571% 0%, 100% 50%, 92.8571428571% 100%, 7.1428571429% 100%, 0 50%, 7.1428571429% 0);
  clip-path: polygon(92.8571428571% 0%, 100% 50%, 92.8571428571% 100%, 7.1428571429% 100%, 0 50%, 7.1428571429% 0);
  content: "";
  background: #5A3F28;
}
.top-morebtn span {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 1.4px;
}
@media screen and (width <= 900px) {
  .top-morebtn span {
    padding-bottom: 3px;
    font-size: 11px;
    letter-spacing: 1.3px;
  }
}
.top-morebtn span::after {
  padding-left: 5px;
  font-size: 10px;
  content: "▶";
}
@media screen and (width <= 900px) {
  .top-morebtn span::after {
    font-size: 8px;
  }
}


#overview .sub_vis{ background: #E0EBE4; height: 120px; display: flex; align-items: center; padding-left: 10vw;background-image: url("../img/sub_vis_img2.svg"); background-repeat: no-repeat; background-size: contain; background-position: right 10vw bottom 0}
#overview .sub_vis_img img{width: 45px; margin-right: 15px}
#overview .sub_vis_txt img{height: 29px}
#overview .overview_inner{width: 860px; margin-left: auto; margin-right: auto; margin-bottom: 100px }
@media screen and (width <= 900px) {
  #overview .overview_inner{width: 90vw;  margin-bottom: 60px }
}
#overview .policy_ttl{ font-size: 20px; color: #34997B; margin-bottom: 35px; letter-spacing: 2px; margin-top: 100px; line-height: 1.6}
@media screen and (width <= 900px) {
  #overview .policy_ttl{ font-size: 16px; margin-bottom: 25px; margin-top: 60px}
}
#overview .policy_txt{ font-size: 13px; line-height: 1.8 }
#overview .policy_line{ border-bottom: solid 1px #34997B; padding-top: 55px; }
@media screen and (width <= 900px) {
  #overview .policy_line{ padding-top: 35px; }
}
#overview .policy_sttl{ font-size: 15px; color: #34997B; margin-top: 30px; margin-bottom: 15px; letter-spacing: 2px;}
@media screen and (width <= 900px) {
  #overview .policy_sttl{ font-size: 14px; }
}

