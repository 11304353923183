#overview.question
			
	._wrap
		padding: 40px 0 0 0
	.qa-nav
		width: 760px
		margin: 70px auto 40px
		&__wrap
			display: flex
			flex-wrap: wrap
			margin: 0 -6px
		a
			+flexCenter
			font-size: 14px
			color: $color-txt-brown
			border: solid 1px $color-brown
			text-decoration: none
			width: 180px
			height: 50px
			margin: 0 6px 12px
			position: relative
			&:hover
				opacity: .7
			&:before
				content: ''
				position: absolute
				left: 0
				top: 0
				bottom: 0
				width: 50px
				background-repeat: no-repeat
				background-position: center center
			&:nth-child(1)
				padding-left: 30px
			&:nth-child(2)
				padding-left: 30px
			&:nth-child(5)
				padding-left: 30px
			&:nth-child(7)
				padding-left: 30px

			&:nth-child(1):before
				background-image: url(../img/question/icon01.svg)
				background-size: 16px 26px
			&:nth-child(2):before
				background-image: url(../img/question/icon02.svg)
				background-size: 34px 19px
			&:nth-child(3):before
				background-image: url(../img/question/icon03.svg)
				background-size: 23px 18px
			&:nth-child(4):before
				background-image: url(../img/question/icon04.svg)
				background-size: 19px 23px
			&:nth-child(5):before
				background-image: url(../img/question/icon05.svg)
				background-size: 24px 24px
			&:nth-child(6):before
				background-image: url(../img/question/icon06.svg)
				background-size: 27px 27px
			&:nth-child(7):before
				background-image: url(../img/question/icon07.svg)
				background-size: 17px 27px
			&:nth-child(8):before
				background-image: url(../img/question/icon08.svg)
				background-size: 22px 24px
		+mq()
			width: px2vwSP(335)
			margin: px2vwSP(40) auto
			&__wrap
				display: flex
				flex-wrap: wrap
				margin: 0 px2vwSP(-6)
			a
				+flexCenter
				font-size: 13px
				width: px2vwSP(160)
				height: px2vwSP(45)
				margin: 0 px2vwSP(6) px2vwSP(12)
				&:hover
					opacity: 1
				&:before
					width: px2vwSP(45)
				&:nth-child(1)
					padding-left: px2vwSP(30)
				&:nth-child(2)
					padding-left: px2vwSP(30)
				&:nth-child(5)
					padding-left: px2vwSP(30)
				&:nth-child(7)
					padding-left: px2vwSP(30)
		
	.qa-main
		background: #ECF2EB
		padding: 40px 0 60px
		min-height: 500px
		&__wrap
			width: 860px
			margin: auto
		&__ttl
			display: flex
			align-items: center
			justify-content: center
			font-size: 20px
			color: $color-txt-brown
			margin-bottom: 40px
			img
				width: 20px
				margin-right: 10px
		&__list
			background: #fff
			border-top: solid 1px $color-green
		&__item
			border-bottom: solid 1px $color-green
		&__q,&__a
			padding: 0 20px 0 20px
			display: flex
			position: relative
			i
				flex-shrink: 0
				+flexCenter
				line-height: 1
				width: 30px
				height: 30px
				font-style: normal
				font-size: 24px
				border-radius: 50%
				margin-right: 20px
			span
				font-size: 13px
				line-height: 1.76923077
		&__q
			height: 80px
			align-items: center
			cursor: pointer
			&:hover
				transition: all 200ms cubic-bezier(0.040, 0.510, 0.155, 0.985)
				opacity: .7
			i
				+fwBold
				color: $color-txt-green
				background: #fff
			span
			&:before,&:after
				content: ''
				position: absolute
				width: 20px
				height: 1px
				background: $color-bg-green
				right: 20px
				top: calc(50%)
			&:after
				transition: all 300ms cubic-bezier(0.040, 0.510, 0.155, 0.985)
				transform-origin: center center
				transform: rotate(90deg)
		&__a
			align-items: flex-start
			padding-bottom: 0em
			opacity: 0
			pointer-events: none
			height: 0
			transform: translate3d(0,-10px,0)
			transition: all 300ms cubic-bezier(0.040, 0.510, 0.155, 0.985)
			i
				+fwBold
				background: $color-bg-brown
				color: #fff !important
				
		&__item.is-open
			.qa-main__q:after
				transform: none
			.qa-main__a
				opacity: 1
				pointer-events: auto
				height: auto
				transform: none
				padding-bottom: 2em

		+mq()
			padding: px2vwSP(25) px2vwSP(20) px2vwSP(40) px2vwSP(20)
			&__wrap
				width: auto
			&__ttl
				font-size: 18px
				margin-bottom: px2vwSP(30)
				img
					width: px2vwSP(45)
			&__q,&__a
				padding: 0 px2vwSP(15) 0 px2vwSP(10)
				i
					width: px2vwSP(30)
					height: px2vwSP(30)
					font-size: 24px
					margin-right: px2vwSP(10)
			&__q
				height: px2vwSP(80)
				&:before,&:after
					width: px2vwSP(15)
					right: px2vwSP(10)
				
	.qa-main__contents
		opacity: 0
		height: 0
		pointer-events: none
		transition: all 300ms cubic-bezier(0.040, 0.510, 0.155, 0.985)
		&[data-index="1"] .qa-main__ttl img
			width: 16px
		&[data-index="2"] .qa-main__ttl img
			width: 34px
		&[data-index="3"] .qa-main__ttl img
			width: 23px
		&[data-index="4"] .qa-main__ttl img
			width: 19px
		&[data-index="5"] .qa-main__ttl img
			width: 24px
		&[data-index="6"] .qa-main__ttl img
			width: 27px
		&[data-index="7"] .qa-main__ttl img
			width: 17px
		&[data-index="8"] .qa-main__ttl img
			width: 22px
				
	&[data-index="1"] .qa-main__contents[data-index="1"],
	&[data-index="2"] .qa-main__contents[data-index="2"],
	&[data-index="3"] .qa-main__contents[data-index="3"],
	&[data-index="4"] .qa-main__contents[data-index="4"],
	&[data-index="5"] .qa-main__contents[data-index="5"],
	&[data-index="6"] .qa-main__contents[data-index="6"],
	&[data-index="7"] .qa-main__contents[data-index="7"],
	&[data-index="8"] .qa-main__contents[data-index="8"]
		opacity: 1
		height: auto
		pointer-events: auto
		transform: none
		
	&[data-index="1"] .qa-nav a[data-index="1"],
	&[data-index="2"] .qa-nav a[data-index="2"],
	&[data-index="3"] .qa-nav a[data-index="3"],
	&[data-index="4"] .qa-nav a[data-index="4"],
	&[data-index="5"] .qa-nav a[data-index="5"],
	&[data-index="6"] .qa-nav a[data-index="6"],
	&[data-index="7"] .qa-nav a[data-index="7"],
	&[data-index="8"] .qa-nav a[data-index="8"]
		background: $color-bg-brown
		color: #fff !important
	&[data-index="1"] .qa-nav a[data-index="1"]:before
		background-image: url(../img/question/icon01-w.svg)
	&[data-index="2"] .qa-nav a[data-index="2"]:before
		background-image: url(../img/question/icon02-w.svg)
	&[data-index="3"] .qa-nav a[data-index="3"]:before
		background-image: url(../img/question/icon03-w.svg)
	&[data-index="4"] .qa-nav a[data-index="4"]:before
		background-image: url(../img/question/icon04-w.svg)
	&[data-index="5"] .qa-nav a[data-index="5"]:before
		background-image: url(../img/question/icon05-w.svg)
	&[data-index="6"] .qa-nav a[data-index="6"]:before
		background-image: url(../img/question/icon06-w.svg)
	&[data-index="7"] .qa-nav a[data-index="7"]:before
		background-image: url(../img/question/icon07-w.svg)
	&[data-index="8"] .qa-nav a[data-index="8"]:before
		background-image: url(../img/question/icon08-w.svg)
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
