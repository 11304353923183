#opencampus
	+fwLight
	color: $color-txt
	font-family: "Noto Sans JP", sans-serif
	background: $color-bg-green-light
	padding-bottom: 100px
	img
		width: 100%
	+mq()
		width: 100vw
		overflow: hidden
	._wrap
		padding: 0
		width: 860px
		margin: auto
		+mq()
			width: px2vwSP(335)
	br._sp
		display: none
		+mq()
			display: inline
	._btn
		width: 190px
		height: 40px
		background: $color-bg-brown
		color: #fff
		display: flex
		justify-content: center
		align-items: center
		+fwBold
		font-size: 14px
		clip-path: polygon(0 50%,5% 0,95% 0,100% 50%,95% 100%,5% 100%)
		padding-left: 0.4em
		span
			display: inline-block
			margin-left: 0.4em
			font-size: 0.7em
			color: $color-txt-yellow			
				
	.oc-head
		width: 1080px
		max-width: 100%
		margin: auto
		height: 979px
		position: relative
		z-index: 2
		+mq()
			height: px2vwSP(866)
	.oc-logo
		position: absolute
		top: 40px
		left: -81px
		width: 796px
		z-index: 1
		+mq()
			width: px2vwSP(360)
			top: px2vwSP(20)
			left: px2vwSP(7)
	.oc-student
		width: 707px
		position: absolute
		top: 160px
		right: -152px
		z-index: 2
		+mq()
			width: px2vwSP(339)
			top: px2vwSP(238)
			left: px2vwSP(61)
	.oc-copy
		position: absolute
		left: 99px
		bottom: 120px
		width: 499px
		z-index: 3
		+mq()
			width: px2vwSP(326)
			bottom: auto
			top: px2vwSP(565)
			left: px2vwSP(26)
	.oc-sheet
		z-index: 1
		width: 1080px
		max-width: 100%
		background: #fff
		margin: auto
		position: relative
		&:before
			content: ''
			background: #fff
			position: absolute
			left: 0
			right: 0
			top: -150px
			height: 150px
			clip-path: polygon(0 100%,50% 0,100% 100%)
		+mq()
			width: px2vwSP(355)
			&:before
				top: px2vwSP(-50.7204)
				height: px2vwSP(50.7204)
	.oc-schedule
		position: relative
		&__head
			width: 173px
			height: 80px
			margin: 0 auto 0px auto
			transform: translate3d(0,-71px,0)			
		&__list
			display: flex
			margin: 0 -10px 0 -10px
			flex-wrap: wrap
		&__item
			width: 420px
			margin: 0px 10px 20px 10px
			background: $color-blue-lighten1
			border-radius: 15px
			padding: 20px 5px 20px 60px
			position: relative
			&:before
				content: ''
				position: absolute
				left: 0
				top: 0
				bottom: 0
				width: 60px
				background: url("../img/openCampus/icon.svg") no-repeat center center / 23px 26px
		&__date
			+fwBold
			color: $color-txt-green
			font-size: 16px
			line-height: 1
			margin-bottom: 0.5em
		&__time
			+fwBold
			color: $color-txt-brown
			font-size: 15px
			line-height: 1
			margin-bottom: 1.5em
		&__ttl
			+fwBold
			color: $color-txt-brown
			font-size: 18px
			line-height: 1
			margin-bottom: 0.5em	
			font-feature-settings: "palt"
			letter-spacing: 0.1em
		&__copy
			+fwBold
			color: $color-txt-brown
			font-size: 13px
			line-height: 1.5
			margin-bottom: 0
		&__link
			padding: 50px 0 75px 0
			display: flex
			justify-content: center
		+mq()
			&__head
				width: px2vwSP(95)
				height: px2vwSP(66)
				transform: translate3d(0,#{px2vwSP(-20)},0)
			&__list
				display: block
				margin: 0 auto
				flex-wrap: wrap
			&__item
				width: auto
				margin: 0px 0 px2vwSP(10) 0
				border-radius: px2vwSP(15)
				padding: px2vwSP(20) px2vwSP(10)
				&:before
					display: none
			&__date
			&__time
			&__ttl
				font-size: 16px
			&__copy
				font-size: 12px
			&__link
				padding: px2vwSP(30) 0 px2vwSP(60) 0
		
	.oc-voice
		border-radius: 10px
		border: dotted 3px $color-green
		padding: 50px
		margin-bottom: 70px
		&__head
			width: 175px
			margin: 0 auto 30px 
		&__list
			list-style-type: none
		&__item
			background: $color-blue-lighten3
			border-radius: 5px
			padding: 20px 30px
			margin-bottom: 18px
		&__label	
			background: $color-bg-green
			border-radius: 8px
			width: 130px
			height: 20px
			display: inline-flex
			justify-content: center
			align-items: center
			+fwBold
			font-size: 13px
			padding: 0 1.5em
			color: #fff
			margin-bottom: 0.3em
		&__body	
			font-size: 15px
			line-height: 1.73333333
		&__imgs	
			display: flex
			justify-content: space-between
		&__img	
			width: 247px
		+mq()
			width: px2vwSP(335)
			border-radius: px2vwSP(10)
			border: dotted 2px $color-green
			padding: px2vwSP(20) px2vwSP(20) px2vwSP(20)
			margin: 0 auto px2vwSP(50) 
			&__head
				width: px2vwSP(175)
				margin: 0 auto px2vwSP(35) 
			&__list
			&__item
				padding: px2vwSP(20) px2vwSP(25)
				margin-bottom: px2vwSP(10)
				&:last-child
					margin-bottom: px2vwSP(30)
			&__label	
			&__body	
				font-size: 13px
				br
					display: none
			&__imgs	
				flex-wrap: wrap
				justify-content: center
				margin: 0 px2vwSP(-8)
			&__img	
				width: px2vwSP(140)
				margin: 0 px2vwSP(6) px2vwSP(12)
				
	.oc-flow
		&__ttl
			background-color: $color-bg-brown
			color: #fff
			height: 80px
			+flexCenter
			+fwMedium
			+letterSpace
			font-size: 22px
			border-radius: 4px
			line-height: 1.68181818
			margin-bottom: 40px
			+mq()
				height: 70px
				font-size: 16px

		&__desc
			line-height: 1.5em
			font-size: 15px
			margin-bottom: 3em
		&__list
			width: 750px
			margin: auto
			
		&__item
			display: flex
			align-items: center
			margin-bottom: 80px
			&:last-child
				margin-bottom: 0
				.oc-flow__body:after
					display: none
		&__circle
			+fwMedium
			font-size: 20px
			+flexCenter
			background: $color-yellow-green
			color: #fff
			border-radius: 50%
			width: 80px
			height: 80px
			margin-right: 20px
		&__body
			width: 650px
			position: relative
			&:after
				content: ''
				position: absolute
				left: 250px
				bottom: -80px
				width: 0
				height: 0
				border-style: solid
				border-width: 22px
				border-color: $color-yellow-green-lighten transparent transparent transparent
		&__body-ttl
			+fwMedium
			line-height: 1
			font-size: 18px
			margin-bottom: 1em
			&:last-child
				margin-bottom: 0
		&__body-list
			list-style-type: none
			padding-left: 1em
			li
				padding: 0
				text-indent: -1em
				line-height: 1.5
				font-size: 15px
		&__time
			width: 260px
			display: flex
			flex-wrap: wrap
			margin-bottom: 1em
		&__time-head
			width: 130px
			font-size: 18px
			+fwBold
			line-height: 1.61111111
		&__time-body
			width: 130px
			font-size: 18px
			line-height: 1.61111111
		&__link
			display: flex
			justify-content: center
			padding: 45px 0 70px 0
		+mq()
			&__desc
				font-size: 13px
			&__list
				width: auto
			&__item
				margin-bottom: px2vwSP(66)
				align-items: flex-start
			&__circle
				font-size: 17.5px
				width: px2vwSP(70)
				height: px2vwSP(70)
				margin-right: px2vwSP(10)
			&__body
				width: px2vwSP(255)
				padding-top: px2vwSP(15)
				&:after
					left: px2vwSP(70)
					bottom: px2vwSP(-70)
					border-width: px2vwSP(22)
			&__body-ttl
				font-size: 14px
				line-height: 1.7
				margin-right: -2em
			&__body-list
				list-style-type: none
				padding-left: 1em
				margin-left: px2vwSP(-80) 
				li
					padding: 0
					text-indent: -1em
					line-height: 1.5
					font-size: 13px
			&__time
				width: px2vwSP(260)
				margin-top: px2vwSP(-10)
			&__time-head
				width: px2vwSP(130)
			&__time-body
				width: px2vwSP(130)
			&__link
				padding: px2vwSP(30) 0 px2vwSP(45) 0
			