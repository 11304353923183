#overview.information
	.if-list
		&__list
		&__item
			border-bottom: dotted 1px $color-brown
			padding: 20px 0
			display: flex
			align-items: flex-start
		&__date
			width: 145px
			color: $color-txt-green
			font-size: 15px
			+fwMedium
		&__main
			width: 715px
		&__ttl
			font-size: 15px
			+fwMedium
			margin-bottom: 1em
		&__body
			font-size: 13px
			line-height: 1.76923077
			margin-bottom: 1em
			&:last-child
				margin-bottom: 0
		&__link
			display: flex
			justify-content: flex-end
		+mq()
			&__item
				display: block
				padding: px2vwSP(30) 0
			&__date
				width: auto
				font-size: 13px
				margin-bottom: 0.5em
			&__main
				width: auto
			&__ttl
				font-size: 14px
				line-height: 1.3
			&__link
				display: flex
				justify-content: center
	.if-pagi
		padding: 50px 0
		display: flex
		justify-content: center
		align-items: center
		&__link
			width: 35px
			height: 35px
			margin: 0 5px
			+flexCenter
			+fwMedium
			font-size: 20px
			color: $color-txt-brown
			border: solid 1px $color-brown
			&.is-current
				background: $color-bg-brown
				color: #fff !important
		&__next,&__prev
			width: 35px
			height: 35px
			background: #f0f0f0
			margin: 0 5px
			+flexCenter
			background: url("../img/infomation/icon.svg") no-repeat center 7px / 18px 40px
		&__prev
			transform: scale(-1,1)
		+mq()
			padding: px2vwSP(50) 0
			&__link
				width: px2vwSP(35)
				height: px2vwSP(35)
				margin: 0 px2vwSP(5)
				font-size: 20px
			&__next,&__prev
				width: px2vwSP(35)
				height: px2vwSP(35)
				margin: 0 px2vwSP(5)
