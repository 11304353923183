#overview.alumni
	
	._section > section
		margin-bottom: 70px
		+mq()
			margin-bottom: px2vwSP(30)
	._desc
		margin-bottom: 1em
	table
		margin-bottom: 20px
	+mq()
		._ttl
			margin-bottom: px2vwSP(20)
		