#overview.social
	
	.so-graph
		display: flex
		justify-content: flex-end
		margin-bottom: 30px
		&__img
			transform: translate3d(-30px,-33px,0)
			width: 544px
		+mq()
			display: block
			margin: px2vwSP(0) 0 px2vwSP(30) 0
			&__img
				transform: translate3d(0,#{px2vwSP(-0)},0)
				width: px2vwSP(335)
	.so-type
		padding: 50px 0 
		&__ttl
			font-size: 16px
			+fwMedium
			+letterSpace
			color: $color-txt-green
			text-align: center
			margin-bottom: 2em
			strong
				text-decoration: underline
		&__frame
			background: $color-blue-lighten2
			padding: 40px 0
		&__list
			display: flex
			justify-content: center
		&__item
			background: #fff
			width: 160px
			height: 160px
			border-radius: 50%
			border: solid 1px $color-green
			+flexCenter
			margin: 0 20px
			font-size: 27px
			color: $color-txt-brown
		+mq()
			padding: px2vwSP(30) 0 px2vwSP(0)
			&__ttl
				font-size: 14px
				line-height: 2.07142857
				margin-bottom: 1.5em
				text-align: left
			&__frame
				padding: px2vwSP(30) 0
			&__item
				width: px2vwSP(90)
				height: px2vwSP(90)
				font-size: 16px
				margin: 0 px2vwSP(5)
		
	.so-dream
		&__frame
			background: $color-blue-lighten2
			padding: 35px 45px 30px
			display: flex
			justify-content: space-between
			align-items: center
			margin-bottom: 30px
		&__prof
			width: 222px
		&__img
			width: 156px
			margin: 0 auto 10px
		&__course
			background: $color-mos-green
			color: #fff
			height: 35px
			border-radius: 3px
			+flexCenter
			font-size: 14px
			+fwMedium
			margin-bottom: 15px
		&__name
			display: flex
			align-items: flex-end
			line-height: 1
			font-size: 16px
			+fwBold
			color: $color-mos-green
			margin-bottom: 0.3em
			span
				font-size: 0.8em
		&__year
			+fwMedium
			color: $color-txt-brown
			font-size: 15px
			margin-bottom: 1em
			font-feature-settings: "palt"
		&__data
			color: $color-txt-brown
			font-size: 13px
			line-height: 1.38461538
			+fwLight			
		&__body
			width: 467px
			text-align: justify
		&__frame.is-orange
			background: $color-orange-lighten2
			.so-dream__name
				color: $color-red
			.so-dream__course
				background: $color-red
		+mq()
			&__frame
				flex-direction: column
				padding: px2vwSP(30) px2vwSP(15)
				margin-bottom: px2vwSP(20)
			&__prof
				width: px2vwSP(222)
				margin-bottom: px2vwSP(40)
			&__img
				width: px2vwSP(156)
				margin: 0 auto px2vwSP(10)
			&__course
				height: px2vwSP(35)
				margin-bottom: px2vwSP(13)
			&__body
				width: auto
				line-height: 2
			&__frame.is-orange
				background: $color-orange-lighten2
				.so-dream__name
					color: $color-red
				.so-dream__course
					background: $color-red
		
			
	+mq()
		.sociel01 ._ttl
			margin-bottom: px2vwSP(30)
		.sociel02 ._ttl
			margin-bottom: px2vwSP(20)
		
			