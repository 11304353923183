#overview.line
			
				
	.li-mv
		margin: 50px 0 20px
		+mq()
			width: px2vwSP(219)
			margin: px2vwSP(25) auto px2vwSP(35)
	.li-btn
		background: $color-blue-lighten2
		height: 128px
		+flexCenter
		margin-bottom: 100px
		&__btn
			width: 233px
		+mq()
			height: px2vwSP(128)
			&__btn
				width: px2vwSP(200)
	.li-info
		margin-bottom: 100px
		&__ttl
			width: 619px
			margin: 0 auto 30px
		&__body
			margin-bottom: 30px
		+mq()
			margin-bottom: px2vwSP(30)
			&__ttl
				width: px2vwSP(314)
				margin: 0 auto px2vwSP(35)
			&__body
				margin-bottom: px2vwSP(25)
	.li-note
		width: 760px
		margin: auto
		border: dotted 3px $color-brown
		padding: 40px 30px 30px
		border-radius: 10px
		&__ttl
			color: $color-txt-brown
			+letterSpace
			+fwMedium
			text-align: center
			margin-bottom: 2em
			font-size: 20px
		&__list
			list-style-type: none
			padding-left: 1em
			li
				font-size: 15px
				line-height: 1.8
				text-indent: -1em			
		+mq()
			width: auto
			padding: px2vwSP(30) px2vwSP(20) px2vwSP(20)
			&__ttl
				font-size: 16px
			&__list
				li
					font-size: 13px
					line-height: 1.7
	
			
		