@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300,400,500,700&display=swap");
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border: 0;
  outline: 0;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main,
details,
hgroup,
menu {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

ul,
ol,
li,
menu,
dir,
figure {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  margin-block: 0 0;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  padding: 0;
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace;
  font-size: 1em;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  line-height: 0;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
  appearance: button;
}

[hidden],
template {
  display: none;
}

input[type=button],
input[type=text],
input[type=search],
input[type=submit],
input[type=image],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

table,
tbody {
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  border: 0;
}

ul li {
  list-style: none;
}

html {
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
}

body {
  position: relative;
  overflow-x: hidden;
  color: #000;
  background: #fff;
  -webkit-font-smoothing: antialiased;
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
}

.body {
  position: relative;
}

.main {
  background: #C0E4F2;
}

.header__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1269px;
  height: 80px;
  padding: 0 12px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .header__top {
    position: relative;
    z-index: 10;
    height: 70px;
    padding: 0 60px 0 12px;
    background: #F5FAFD;
    -webkit-box-shadow: 0 2px 2px rgba(203, 203, 203, 0.75);
    box-shadow: 0 2px 2px rgba(203, 203, 203, 0.75);
  }
}
.header__title {
  display: block;
  width: 530px;
  margin: 10px 0 8px;
}

@media screen and (max-width: 1180px) {
  .header__title {
    width: 205px;
    margin: 23.5px 0;
  }
}
@media screen and (width <= 900px) {
  .header__title {
    margin: 16px 0;
  }
}
.header__logo {
  line-height: 0;
}

.header__top-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
}

.header__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  margin-top: 27px;
}

@media screen and (width <= 900px) {
  .header__menu {
    display: none;
  }
}
.header__menu-list {
  width: 115px;
}

.header__menu-list:nth-of-type(1) {
  width: 180px;
}

.header__menu-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  color: #5A3F28;
  text-indent: -1px;
  letter-spacing: 1px;
  background: #C6E3DA;
}

.header__menu-arrow {
  display: block;
  padding-right: 5px;
  font-size: 5px;
  color: #4DA68B;
}

.header__access {
  display: block;
  margin-top: 18px;
  font-size: 16px;
  font-weight: 500;
  color: #5A3F28;
  text-align: center;
}

@media screen and (width <= 900px) {
  .header__access {
    margin-top: 23px;
    font-size: 10px;
    letter-spacing: 0.2px;
  }
}
.header__access-img {
  width: 22px;
}

@media screen and (width <= 900px) {
  .header__access-img {
    width: 11px;
  }
}
@media screen and (width <= 900px) {
  .header__access-text {
    margin-top: 5px;
  }
}
.header__bottom {
  background: #5A3F28;
}

@media screen and (width <= 900px) {
  .header__bottom {
    display: none;
  }
}
.header__navul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 1300px;
  padding: 10px 0;
  margin: 0 auto;
}

.header__navli {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  line-height: 18px;
}

.header__navli::after {
  display: block;
  content: "";
  border-right: 1px solid #fff;
}

.header__navli:last-of-type::after {
  content: none;
}

.header__navlink {
  margin: 0 2em;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 2px;
}

.sp-nav__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 2s;
  transition: 2s;
}

.sp-nav__wrapper.scroll, .sp-nav__wrapper.open {
  -webkit-box-shadow: 0 2px 2px 2px rgba(203, 203, 203, 0.5);
  box-shadow: 0 2px 2px 2px rgba(203, 203, 203, 0.5);
}

.sp-nav__line {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  display: none;
  width: 60px;
  height: 70px;
  padding-right: 5px;
  cursor: pointer;
  background: #F5FAFD;
}

@media screen and (width <= 900px) {
  .sp-nav__line {
    display: block;
  }
}
.sp-nav__line.scroll, .sp-nav__line.open {
  position: static;
  width: 65px;
  padding-left: 5px;
}

.sp-nav__in {
  width: 20px;
  padding: 22px 0;
  margin: 0 auto;
}

.sp-nav__border1, .sp-nav__border2, .sp-nav__border3 {
  display: block;
  width: 20px;
  height: 2px;
  margin: 5px 0;
  background-color: #5a3f28;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sp-nav__border1.open {
  width: 26px;
  -webkit-transform: rotate(39deg) translate(2px, 7px);
  transform: rotate(39deg) translate(2px, 7px);
}

.sp-nav__border2.open {
  opacity: 0;
}

.sp-nav__border3.open {
  width: 26px;
  -webkit-transform: rotate(-39deg) translate(2px, -7px);
  transform: rotate(-39deg) translate(2px, -7px);
}

.sp-nav__icon {
  display: none;
  gap: 7px;
  width: 248px;
  height: 70px;
  padding: 16px 15px;
  background: #D4EAE4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-75px);
  transform: translateY(-75px);
}

@media screen and (width <= 900px) {
  .sp-nav__icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.sp-nav__icon.scroll, .sp-nav__icon.open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.sp-nav__icon-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 38px;
  height: 38px;
}

.sp-nav__icon-link_line {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.48px;
  background: #00B900;
}

.sp-nav__main {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  background: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(105vw);
  transform: translateX(105vw);
}

.sp-nav__main.open {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.sp-nav__box {
  padding-top: 70px;
  background: #C0E4F1;
}

.sp-nav__only {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 51px;
  padding: 0 0 0 20px;
  font-size: 15px;
  font-weight: 500;
  color: #5A3F28;
  letter-spacing: 1.5px;
  cursor: pointer;
  background: #C0E4F1;
  border-bottom: 1px solid #fff;
}

.sp-nav__only::after {
  position: absolute;
  top: 50%;
  right: 10px;
  display: block;
  color: #34997B;
  content: "〉";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sp-nav__parent {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 51px;
  padding: 0 0 0 20px;
  font-size: 15px;
  font-weight: 500;
  color: #5A3F28;
  letter-spacing: 1.5px;
  cursor: pointer;
  background: #C0E4F1;
  border-bottom: 1px solid #fff;
}

.sp-nav__parent::after, .sp-nav__parent::before {
  position: absolute;
  top: 50%;
  display: block;
  content: "";
  background: #34997B;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sp-nav__parent::after {
  right: 24px;
  width: 1px;
  height: 8px;
}

.sp-nav__parent::before {
  right: 20px;
  width: 8px;
  height: 1px;
}

.sp-nav__parent.open::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sp-nav__parent.open::before {
  opacity: 0;
}

.sp-nav__child {
  display: none;
  padding: 16px 45px;
  background: #fff;
}

.sp-nav__childul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.sp-nav__childli {
  display: block;
  width: 140px;
}

.sp-nav__child-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  line-height: 34px;
  color: #5A3F28;
  letter-spacing: 1.5px;
}

.sp-nav__child-link::before {
  display: block;
  font-size: 12px;
  color: #34997B;
  content: "〉";
}

.sp-nav__menu-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  margin-bottom: 1px;
  font-size: 13px;
  color: #5A3F28;
  letter-spacing: 1.56px;
  background: #DFF0F4;
}

.sp-nav__menu-arrow {
  margin-right: 7px;
  font-size: 10px;
  color: #34997B;
}

.sp-nav__foot {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 26px;
}

.sp-nav__foot:last-of-type {
  margin-top: 0;
}

.sp-nav__footli {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
}

.sp-nav__footli::after {
  display: block;
  width: 1px;
  height: 12px;
  content: "";
  background: #5A3F28;
}

.sp-nav__footli:last-of-type::after {
  content: none;
}

.sp-nav__foot-link {
  display: block;
  padding: 0 1em;
  font-size: 12px;
  line-height: 24px;
  color: #5A3F28;
  letter-spacing: 1.92px;
}

.aside__nav {
  position: fixed;
  top: 125px;
  right: 0;
  z-index: 1000;
  display: block;
}

@media screen and (width <= 900px) {
  .aside__nav {
    display: none;
  }
}
.aside__navli {
  display: block;
  margin-bottom: 4px;
}

.aside__navli:nth-of-type(1) * {
  height: 150px;
  background: #E95504;
}

.aside__navli:nth-of-type(2) * {
  height: 150px;
  background: #009EA7;
}

.aside__navli:nth-of-type(3) * {
  height: 150px;
  background: #725C48;
}

.aside__navli:nth-of-type(4) *, .aside__navli:nth-of-type(5) * {
  height: 60px;
  background: transparent;
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
}

.aside__navlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  letter-spacing: -0.35px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

.aside__navimg {
  width: 60px;
}

.aside__sp {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: none;
  width: 100%;
  height: 70px;
  overflow: auto hidden;
  background: #fff;
  -webkit-box-shadow: 0 -1px 2px rgba(220, 220, 220, 0.5);
  box-shadow: 0 -1px 2px rgba(220, 220, 220, 0.5);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(75px);
  transform: translateY(75px);
}

@media screen and (width <= 900px) {
  .aside__sp {
    display: block;
  }
}
.aside__sp.scroll {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.aside__spul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 14px;
}

.aside__spli {
  display: block;
}

.aside__spli:nth-of-type(1) * {
  background: #E95504;
}

.aside__spli:nth-of-type(2) * {
  background: #009EA7;
}

.aside__spli:nth-of-type(3) * {
  background: #5A3F28;
}

.aside__splink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 115px;
  height: 42px;
  font-size: 11px;
  line-height: 12.82px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.39px;
}

.footer {
  padding-top: 100px;
  border-top: 3px solid #5A3F28;
}

@media screen and (width <= 900px) {
  .footer {
    padding-top: 60px;
    border-top: 2px solid #5A3F28;
  }
}
@media screen and (width <= 900px) {
  .footer {
    margin-bottom: 70px;
  }
}
.footer__iconborder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .footer__iconborder {
    display: block;
  }
}
.footer__iconborder::before, .footer__iconborder::after {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 280px;
  height: 1px;
  content: "";
  background: #008842;
}

@media screen and (width <= 900px) {
  .footer__iconborder::before, .footer__iconborder::after {
    content: none;
  }
}
.footer__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 340px;
}

@media screen and (width <= 900px) {
  .footer__icon {
    width: 290px;
    margin: 0 auto;
  }
}
.footer__icon-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 19px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.76px;
}

@media screen and (width <= 900px) {
  .footer__icon-link {
    width: 40px;
    height: 40px;
    font-size: 16px;
    letter-spacing: -0.64px;
  }
}
.footer__icon-link_line {
  background: #00B900;
}

.footer__menu0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 28px;
  max-width: 1044px;
  padding: 55px 24px 45px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .footer__menu0 {
    display: none;
  }
}
.footer__parent, .footer__only {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 4px;
  font-size: 17px;
  font-weight: 500;
  line-height: 42px;
  color: #5A3F28;
  letter-spacing: 1.7px;
}

.footer__parent::before, .footer__only::before {
  display: block;
  color: #34997B;
  content: "・";
}

.footer__only {
  margin-top: 10px;
}

.footer__childli {
  margin-left: 22px;
}

.footer__child-link, .footer__child-link2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 0;
  font-size: 15px;
  line-height: 22px;
  color: #5A3F28;
  letter-spacing: 1.5px;
}

.footer__child-link::before {
  display: block;
  color: #34997B;
  content: "〉";
}

.footer__child-link2 {
  position: relative;
  padding-left: 12px;
  margin-left: 24px;
}

.footer__agl {
  position: absolute;
  top: 7px;
  left: 0;
  display: block;
  width: 9px;
}

.footer__menu2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 1044px;
  padding: 0 24px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .footer__menu2 {
    display: none;
  }
}
.footer__menu2-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  font-size: 15px;
  color: #5A3F28;
  letter-spacing: 1.8px;
  background: #C6E3DA;
}

.footer__menu2-arrow {
  margin-right: 2px;
  font-size: 10px;
  color: #34997B;
}

.footer__m3s {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 45px;
}

@media screen and (width <= 900px) {
  .footer__m3s {
    display: block;
    margin-top: 40px;
  }
}
.footer__menu3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer__menu3-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 22px;
}

@media screen and (width <= 900px) {
  .footer__menu3-list {
    height: 29px;
  }
}
.footer__menu3-list::after {
  display: block;
  width: 1px;
  height: 14px;
  content: "";
  background: #5A3F28;
}

@media screen and (width <= 900px) {
  .footer__menu3-list:last-of-type::after {
    content: none;
  }
}
.footer__menu3-listlast {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 22px;
}

@media screen and (width <= 900px) {
  .footer__menu3-listlast {
    height: 29px;
  }
}
.footer__menu3-link {
  display: block;
  padding: 0 1em;
  font-size: 14px;
  line-height: 22px;
  color: #5A3F28;
  letter-spacing: 1.68px;
}

@media screen and (width <= 900px) {
  .footer__menu3-link {
    font-size: 13px;
    line-height: 29px;
    letter-spacing: 1.56px;
  }
}
.footer__box {
  background: #EEECEA;
}

.footer__boxin {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 62px;
  max-width: 1050px;
  padding: 40px 25px;
  margin: 75px auto 0;
}

@media screen and (width <= 900px) {
  .footer__boxin {
    display: block;
    padding: 35px 25px 30px;
    margin: 40px auto 0;
  }
}
.footer__boxlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 170px;
  height: 84px;
  font-size: 18px;
  font-weight: 500;
  color: #5A3F28;
  letter-spacing: 1.8px;
  border: 1px solid #5A3F28;
}

@media screen and (width <= 900px) {
  .footer__boxlink {
    width: 290px;
    height: 35px;
    margin: 0 auto;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
}
.footer__flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 62px;
}

@media screen and (max-width: 1000px) {
  .footer__flex {
    width: 500px;
  }
}
@media screen and (width <= 900px) {
  .footer__flex {
    display: block;
    width: 290px;
    margin: 21px auto 0;
  }
}
.footer__menu4, .footer__menu5, .footer__menu6 {
  display: block;
}

@media screen and (width <= 900px) {
  .footer__menu5-list:nth-of-type(3) {
    padding-bottom: 8px;
  }
}
.footer__menu4-link {
  margin-bottom: 10px;
}

@media screen and (width <= 900px) {
  .footer__menu4-link {
    margin: 0;
  }
}
.footer__menu5-link, .footer__menu6-link {
  margin-bottom: 5px;
}

@media screen and (width <= 900px) {
  .footer__menu5-link, .footer__menu6-link {
    margin: 0;
  }
}
.footer__menu4-link, .footer__menu5-link, .footer__menu6-link {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #5A3F28;
  letter-spacing: 1.6px;
}

.footer__menu4-link::before, .footer__menu5-link::before, .footer__menu6-link::before {
  content: "〉";
}

@media screen and (width <= 900px) {
  .footer__menu4-link, .footer__menu5-link, .footer__menu6-link {
    padding: 6px 0;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
}
.footer__menu5-link-ss, .footer__menu6-link-ss {
  display: block;
  margin-left: 25px;
  font-size: 14px;
  line-height: 20px;
  color: #5A3F28;
  letter-spacing: 1.12px;
}

@media screen and (width <= 900px) {
  .footer__menu5-link-ss, .footer__menu6-link-ss {
    font-size: 13px;
    letter-spacing: 1.04px;
  }
}
.footer__angle {
  display: inline-block;
  width: 9px;
  margin-bottom: 4px;
}

.footer__dot {
  display: none;
}

@media screen and (width <= 900px) {
  .footer__dot {
    display: block;
    height: 3px;
    line-height: 0;
  }
}
.footer__linedot {
  display: block;
  width: 290px;
  max-width: none;
  height: 3px;
  -o-object-fit: cover;
  object-fit: cover;
}

.footer__copyright {
  padding: 45px;
  background: #008842;
}

@media screen and (width <= 900px) {
  .footer__copyright {
    padding: 26px 0;
  }
}
.footer__cptext {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  letter-spacing: 1.2px;
}

@media screen and (width <= 900px) {
  .footer__cptext {
    font-size: 8px;
    letter-spacing: 0.96px;
  }
}
.pagetop {
  position: relative;
}

.pagetop__link {
  position: absolute;
  right: 80px;
  bottom: 55px;
  z-index: 20;
  width: 80px;
}

@media screen and (width <= 900px) {
  .pagetop__link {
    right: 24px;
    bottom: 40px;
    width: 50px;
  }
}
.top-mainvisual {
  position: relative;
  padding-top: 110px;
}

@media screen and (max-width: 1300px) {
  .top-mainvisual {
    padding-top: 8.4615384615vw;
  }
}
@media screen and (width <= 900px) {
  .top-mainvisual {
    padding-top: 67px;
  }
}
.top-mainvisual__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: block;
  max-width: 1300px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .top-mainvisual__bg {
    max-width: 375px;
  }
}
.top-mainvisual__main {
  position: relative;
  z-index: 10;
  max-width: 581px;
  margin: 0 auto;
}

@media screen and (max-width: 1300px) {
  .top-mainvisual__main {
    width: 44.6923076923vw;
  }
}
@media screen and (width <= 900px) {
  .top-mainvisual__main {
    width: 290px;
  }
}
.top-news {
  position: relative;
  z-index: 10;
  max-width: 888px;
  padding: 0 14px;
  margin: 64px auto 0;
}

.top-news__line {
  display: block;
  max-width: 860px;
  height: 3px;
  line-height: 0;
}

.top-news__lineimg {
  width: 857px;
  max-width: none;
  height: 3px;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (width <= 900px) {
  .top-news__lineimg {
    width: calc(100vw - 28px);
  }
}
.top-news__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  margin: 25px 0;
}

@media screen and (width <= 900px) {
  .top-news__wrapper {
    display: block;
    margin: 18px 18px 40px;
  }
}
.top-news__icon {
  display: block;
  width: 138px;
}

@media screen and (width <= 900px) {
  .top-news__icon {
    width: 146px;
    margin: 0 auto;
  }
}
.top-news__box {
  display: block;
  margin-top: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #5A3F28;
  letter-spacing: 1.92px;
}

.top-news__box:last-of-type {
  margin-bottom: 0;
}

@media screen and (width <= 900px) {
  .top-news__box:last-of-type {
    display: none;
  }
}
.top-news__date {
  color: #8B7869;
}

.top-news__show {
  position: absolute;
  right: 14px;
  bottom: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #5A3F28;
}

@media screen and (width <= 900px) {
  .top-news__show {
    right: 32px;
    bottom: 15px;
  }
}
.top-news__arrow {
  display: block;
  width: 10px;
  margin-left: 10px;
  font-size: 10px;
  color: #008842;
}

.top-box0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 638px;
  padding: 0 14px;
  margin: 85px auto 0;
}

.top-box0__btn {
  display: block;
  width: 290px;
}

@media screen and (width <= 900px) {
  .top-box0__btn {
    width: 238px;
  }
}
.top-movie {
  position: relative;
}

.top-movie__bg {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  margin: 0 auto;
}

.top-movie__bgimg {
  position: relative;
  left: 50%;
  display: block;
  width: 100%;
  max-width: 2559px;
  height: 461px;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -o-object-fit: none;
  object-fit: none;
}

@media screen and (max-width: 700px) {
  .top-movie__bgimg {
    right: 0;
    left: 0;
    height: auto;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -o-object-fit: fill;
    object-fit: fill;
  }
}
.top-movie__wrapper {
  position: relative;
  z-index: 20;
  padding: 90px 0 75px;
}

@media screen and (width <= 900px) {
  .top-movie__wrapper {
    padding: 52px 0 23px;
  }
}
.top-movie__title {
  width: 322px;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .top-movie__title {
    width: 210px;
  }
}
.top-movie__btn {
  display: block;
  width: 606px;
  padding: 0;
  margin: 32px auto 0;
  cursor: pointer;
  background: transparent;
  border: none;
}

@media screen and (max-width: 700px) {
  .top-movie__btn {
    width: 227px;
    margin-top: 23px;
  }
}
.top-movie__modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.top-movie__modal.open {
  display: block;
}

.top-movie__youtube {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 800px;
  height: 100%;
  padding: 25px;
  margin: 0 auto;
}

.top-movie__youtube-wrap {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9のアスペクト比を保つためのパディング */
}

.top-movie__youtube-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.top-fe__outer {
  padding: 90px 15px 0;
}

@media screen and (width <= 900px) {
  .top-fe__outer {
    padding-top: 50px;
  }
}
.top-fe__wrapper {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
}

.top-fe__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: block;
}

.top-fe__bgimg {
  height: 230px;
}

@media screen and (width <= 900px) {
  .top-fe__bgimg {
    height: 100px;
  }
}
.top-fe__title {
  position: relative;
  z-index: 5;
  width: 169px;
  padding-top: 90px;
  margin: 0 auto 50px;
}

@media screen and (width <= 900px) {
  .top-fe__title {
    width: 126px;
    height: 95px;
    padding-top: 50px;
    margin-bottom: 0;
  }
}
.top-fe__white {
  padding-bottom: 60px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}

@media screen and (width <= 900px) {
  .top-fe__white {
    padding-bottom: 50px;
    border-radius: 0 0 3px 3px;
  }
}
.top-fe__boxouter1 {
  position: relative;
  max-width: 966px;
  padding-top: 14px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__boxouter1 {
    max-width: 345px;
    padding-top: 28px;
  }
}
.top-fe__bgbg1 {
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 5;
  width: 220px;
}

@media screen and (width <= 900px) {
  .top-fe__bgbg1 {
    top: 0;
    width: 98px;
  }
}
.top-fe__box1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 770px;
  padding: 56px 0 0;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__box1 {
    padding: 0 0 0 32px;
  }
}
.top-fe__col1 {
  padding-right: 5px;
}

@media screen and (width <= 900px) {
  .top-fe__col1 {
    padding: 47px 20px 0 0;
  }
}
.top-fe__point1 {
  position: relative;
  z-index: 5;
  width: 205px;
  margin-bottom: 25px;
}

@media screen and (width <= 900px) {
  .top-fe__point1 {
    width: 150px;
  }
}
.top-fe__p1-imgflex {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  padding-bottom: 72px;
}

@media screen and (width <= 900px) {
  .top-fe__p1-imgflex {
    display: block;
    padding-bottom: 50px;
  }
}
.top-fe__p1-img {
  display: block;
  width: 270px;
}

@media screen and (width <= 900px) {
  .top-fe__p1-img {
    width: 125px;
  }
}
.top-fe__p1-img_no2 {
  margin-top: 5px;
}

.top-fe__deco1 {
  position: absolute;
  right: 120px;
  bottom: 0;
  z-index: 10;
  display: block;
  width: 366px;
}

@media screen and (width <= 900px) {
  .top-fe__deco1 {
    right: 4px;
    width: 154px;
  }
}
.top-fe__boxouter2 {
  position: relative;
  max-width: 950px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__boxouter2 {
    max-width: 345px;
    margin: 30px auto 0;
  }
}
.top-fe__bgbg2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 220px;
}

@media screen and (width <= 900px) {
  .top-fe__bgbg2 {
    top: 18px;
    right: 5px;
    width: 103px;
  }
}
.top-fe__box2 {
  position: relative;
  max-width: 770px;
  padding: 22px 0 0;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__box2 {
    max-width: 320px;
    padding: 0;
  }
}
.top-fe__col2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 100px;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
  padding-top: 40px;
}

@media screen and (width <= 900px) {
  .top-fe__col2 {
    display: block;
    max-width: 289px;
    padding-top: 70px;
    margin: 0 auto;
  }
}
.top-fe__deco2 {
  display: block;
  width: 200px;
}

@media screen and (width <= 900px) {
  .top-fe__deco2 {
    position: absolute;
    top: 0;
    left: 35px;
    width: 110px;
  }
}
.top-fe__point2 {
  display: block;
  width: 410px;
}

@media screen and (width <= 900px) {
  .top-fe__point2 {
    width: 281px;
  }
}
.top-fe__imgflex2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  padding-top: 20px;
}

@media screen and (width <= 900px) {
  .top-fe__imgflex2 {
    padding-top: 10px;
  }
}
.top-fe__btn2 {
  margin: 22px 0 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__btn2 {
    margin-top: 15px;
  }
}
.top-fe__34outer {
  max-width: 938px;
  padding-top: 30px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__34outer {
    max-width: 345px;
    padding-top: 2px;
  }
}
.top-fe__34 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 814px;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .top-fe__34 {
    padding-left: 7.7066666667vw;
  }
}
@media screen and (width <= 900px) {
  .top-fe__34 {
    display: block;
    padding-left: 0;
  }
}
.top-fe__box3 {
  position: relative;
  width: 340px;
  margin-right: 32px;
}

@media screen and (max-width: 1030px) {
  .top-fe__box3 {
    margin-right: 0.2222222222vw;
  }
}
.top-fe__bgbg3 {
  position: absolute;
  top: 0;
  left: -85px;
  z-index: 5;
  width: 150px;
}

@media screen and (max-width: 1000px) {
  .top-fe__bgbg3 {
    left: -5.7894736842vw;
  }
}
@media screen and (width <= 900px) {
  .top-fe__bgbg3 {
    left: 0;
    width: 65px;
  }
}
.top-fe__point3 {
  width: 240px;
  padding: 80px 0 0 18px;
}

@media screen and (width <= 900px) {
  .top-fe__point3 {
    padding: 40px 0 0;
    margin-left: 33px;
  }
}
@media screen and (width <= 900px) {
  .top-fe__p3-sp {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: end;
    -ms-flex-align: end;
    align-items: end;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 20px;
  }
}
.top-fe__p3img-outer {
  position: relative;
  padding: 42px 98px 23px 0;
}

@media screen and (width <= 900px) {
  .top-fe__p3img-outer {
    position: static;
    padding: 0;
  }
}
.top-fe__btn3, .top-fe__btn4 {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media screen and (width <= 900px) {
  .top-fe__btn3, .top-fe__btn4 {
    left: 0;
    width: 103px;
    margin: 0 auto;
  }
}
@media screen and (width <= 900px) {
  .top-fe__btn4 {
    bottom: 24px;
  }
}
.top-fe__p3-img {
  display: block;
  width: 240px;
}

@media screen and (width <= 900px) {
  .top-fe__p3-img {
    width: 125px;
    margin: 0 0 0 30px;
  }
}
.top-fe__deco3 {
  display: block;
  width: 262px;
  padding: 5px 0 0 5px;
}

@media screen and (width <= 900px) {
  .top-fe__deco3 {
    width: 135px;
    padding: 0;
    margin-right: 20px;
  }
}
.top-fe__box4 {
  position: relative;
  width: 441px;
  padding-left: 94px;
}

@media screen and (max-width: 1000px) {
  .top-fe__box4 {
    width: calc(347px + 9.4vw);
    padding-left: 9.4vw;
  }
}
@media screen and (width <= 900px) {
  .top-fe__box4 {
    width: 345px;
    padding: 0;
    margin: 45px auto 0;
  }
}
.top-fe__bgbg4 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 206px;
}

@media screen and (width <= 900px) {
  .top-fe__bgbg4 {
    right: 15px;
    left: auto;
    width: 59px;
  }
}
.top-fe__point4 {
  width: 265px;
  padding: 80px 0 0 18px;
}

@media screen and (width <= 900px) {
  .top-fe__point4 {
    width: 262px;
    padding: 15px 0 0;
    margin-left: 30px;
  }
}
@media screen and (width <= 900px) {
  .top-fe__p4-sp {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 20px;
  }
}
.top-fe__p4img-outer {
  position: relative;
  padding: 11px 98px 23px 0;
}

@media screen and (width <= 900px) {
  .top-fe__p4img-outer {
    position: static;
    padding: 0;
  }
}
.top-fe__p4-img {
  display: block;
  width: 240px;
}

@media screen and (width <= 900px) {
  .top-fe__p4-img {
    width: 125px;
    margin: 0 0 0 30px;
  }
}
.top-fe__deco4 {
  display: block;
  width: 299px;
  padding: 27px 0 0 25px;
}

@media screen and (width <= 900px) {
  .top-fe__deco4 {
    width: 148px;
    padding: 0;
    margin-top: 33px;
  }
}
.top-fe__boxouter5 {
  position: relative;
  max-width: 990px;
  margin: 50px auto 0;
}

@media screen and (width <= 900px) {
  .top-fe__boxouter5 {
    max-width: 345px;
    margin-top: 10px;
  }
}
.top-fe__bgbg5 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 220px;
}

@media screen and (width <= 900px) {
  .top-fe__bgbg5 {
    left: 2px;
    width: 98px;
  }
}
.top-fe__box5 {
  position: relative;
  max-width: 810px;
  padding: 95px 0 10px;
  margin: 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__box5 {
    padding: 45px 0 0;
  }
}
.top-fe__point5 {
  width: 693px;
}

@media screen and (width <= 900px) {
  .top-fe__point5 {
    width: 300px;
    margin: 0 0 0 30px;
  }
}
.top-fe__imgflex5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
}

@media screen and (width <= 900px) {
  .top-fe__imgflex5 {
    gap: 0;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 15px 15px 0;
  }
}
.top-fe__col5 {
  width: 379px;
  padding-top: 45px;
}

@media screen and (width <= 900px) {
  .top-fe__col5 {
    width: 150px;
    padding-top: 15px;
  }
}
.top-fe__col51 {
  width: 395px;
}

@media screen and (width <= 900px) {
  .top-fe__col51 {
    width: 150px;
  }
}
.top-fe__p5-img {
  display: block;
  width: 345px;
  margin: 0 0 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__p5-img {
    width: 138px;
  }
}
.top-fe__deco5 {
  display: block;
  width: 234px;
  padding-top: 5px;
}

@media screen and (width <= 900px) {
  .top-fe__deco5 {
    width: 105px;
  }
}
.top-fe__deco51 {
  display: block;
  width: 189px;
  margin: 0 0 0 auto;
}

@media screen and (width <= 900px) {
  .top-fe__deco51 {
    width: 83px;
  }
}
.top-fe__p51-img {
  display: block;
  width: 345px;
  padding-top: 5px;
}

@media screen and (width <= 900px) {
  .top-fe__p51-img {
    width: 138px;
  }
}
.top-fe__btn5 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
}

@media screen and (width <= 900px) {
  .top-fe__btn5 {
    position: static;
    margin: 0 15px;
  }
}
@media screen and (width <= 900px) {
  .top-fe__btn5-in {
    margin: 0 0 0 auto;
  }
}
.top-morebtn {
  position: relative;
  z-index: 5;
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 140px;
  height: 40px;
}

@media screen and (width <= 900px) {
  .top-morebtn {
    width: 103px;
    height: 30px;
  }
}
.top-morebtn::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(92.8571428571% 0%, 100% 50%, 92.8571428571% 100%, 7.1428571429% 100%, 0 50%, 7.1428571429% 0);
  clip-path: polygon(92.8571428571% 0%, 100% 50%, 92.8571428571% 100%, 7.1428571429% 100%, 0 50%, 7.1428571429% 0);
  content: "";
  background: #5A3F28;
}

.top-morebtn span {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 1.4px;
}

@media screen and (width <= 900px) {
  .top-morebtn span {
    padding-bottom: 3px;
    font-size: 11px;
    letter-spacing: 1.3px;
  }
}
.top-morebtn span::after {
  padding-left: 5px;
  font-size: 10px;
  content: "▶";
}

@media screen and (width <= 900px) {
  .top-morebtn span::after {
    font-size: 8px;
  }
}
#overview .sub_vis {
  background: #E0EBE4;
  height: 120px;
  display: flex;
  align-items: center;
  padding-left: 10vw;
  background-image: url("../img/sub_vis_img2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right 10vw bottom 0;
}

#overview .sub_vis_img img {
  width: 45px;
  margin-right: 15px;
}

#overview .sub_vis_txt img {
  height: 29px;
}

#overview .overview_inner {
  width: 860px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

@media screen and (width <= 900px) {
  #overview .overview_inner {
    width: 90vw;
    margin-bottom: 60px;
  }
}
#overview .policy_ttl {
  font-size: 20px;
  color: #34997B;
  margin-bottom: 35px;
  letter-spacing: 2px;
  margin-top: 100px;
  line-height: 1.6;
}

@media screen and (width <= 900px) {
  #overview .policy_ttl {
    font-size: 16px;
    margin-bottom: 25px;
    margin-top: 60px;
  }
}
#overview .policy_txt {
  font-size: 13px;
  line-height: 1.8;
}

#overview .policy_line {
  border-bottom: solid 1px #34997B;
  padding-top: 55px;
}

@media screen and (width <= 900px) {
  #overview .policy_line {
    padding-top: 35px;
  }
}
#overview .policy_sttl {
  font-size: 15px;
  color: #34997B;
  margin-top: 30px;
  margin-bottom: 15px;
  letter-spacing: 2px;
}

@media screen and (width <= 900px) {
  #overview .policy_sttl {
    font-size: 14px;
  }
}
#overview {
  color: #040000;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
}
@media screen and (max-width: 900px) {
  #overview {
    width: 100vw;
    overflow: hidden;
  }
}
#overview p a, #overview li a {
  font-weight: 700;
  color: #53977C !important;
  text-decoration: underline;
}
#overview ._link-icon {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: 300;
  margin-right: 0.3em;
}
#overview ._link-icon:after {
  content: "";
  width: 1em;
  height: 1em;
  background: url("../img/common/icon-link.svg") no-repeat center center/contain;
}
#overview br._sp {
  display: none;
}
@media screen and (max-width: 900px) {
  #overview br._sp {
    display: inline;
  }
}
#overview ._palt {
  font-feature-settings: "palt";
}
#overview ._wrap {
  padding: 100px 0 150px 0;
  width: 860px;
  margin: auto;
}
@media screen and (max-width: 900px) {
  #overview ._wrap {
    width: 89.3333333333vw;
    padding: 8vw 0 13.3333333333vw 0;
  }
}
#overview ._section {
  margin-bottom: 50px;
}
#overview ._section.is-space01 {
  margin-bottom: 75px;
}
#overview ._section.is-space02 {
  margin-bottom: 100px;
}
@media screen and (max-width: 900px) {
  #overview ._section.is-space01 {
    margin-bottom: 8vw;
  }
  #overview ._section.is-space02 {
    margin-bottom: 16vw;
  }
}
#overview ._ttl {
  background-color: #53977C;
  color: #fff;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  font-size: 22px;
  border-radius: 4px;
  line-height: 1.68181818;
  margin-bottom: 40px;
  text-align: center;
  padding: 1em 0;
}
#overview ._ttl.is-space {
  margin-bottom: 80px;
}
@media screen and (max-width: 900px) {
  #overview ._ttl {
    min-height: 70px;
    font-size: 16px;
  }
}
#overview ._ttl-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: inline-flex;
  height: 28px;
  font-size: 15px;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  border: solid 1px #55402B;
  color: #55402B;
  padding: 0 0.7em 0 0.9em;
  margin-bottom: 1em;
}
#overview ._ttl-bold {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  margin-bottom: 1em;
}
@media screen and (max-width: 900px) {
  #overview ._ttl-bold {
    line-height: 1.3;
    font-size: 13px;
  }
}
#overview ._ttl-green {
  font-weight: 500;
  color: #53977C;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  font-size: 20px;
  margin-bottom: 1em;
}
@media screen and (max-width: 900px) {
  #overview ._ttl-green {
    line-height: 1.3;
    font-size: 15px;
  }
}
#overview ._ttl-outline {
  font-size: 20px;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  border: solid 1px #55402B;
  color: #EA5504;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  margin-bottom: 40px;
}
@media screen and (max-width: 900px) {
  #overview ._ttl-outline {
    letter-spacing: 0;
    font-size: 16px;
    min-height: 14.6666666667vw;
  }
}
#overview ._body1 {
  font-size: 13px;
  line-height: 2.15384615;
  margin-bottom: 1em;
}
#overview ._body1.is-space {
  margin-bottom: 2em;
}
#overview ._body2 {
  font-size: 12px;
  line-height: 1.66666667;
  margin-bottom: 1em;
}
#overview ._body2.is-space {
  margin-bottom: 2em;
}
#overview ._body3 {
  font-size: 13px;
  line-height: 1.5em;
  margin-bottom: 1em;
}
#overview ._body3.is-space {
  margin-bottom: 2em;
}
#overview ._desc {
  font-weight: 300;
  font-size: 15px;
  line-height: 2.2;
}
#overview ._desc strong {
  position: relative;
  display: inline-block;
  color: #53977C;
  font-size: 1.3em;
  font-feature-settings: "palt";
  letter-spacing: 0.1em;
}
#overview ._desc strong:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: #E2EBE0;
  left: 0;
  right: 0;
  bottom: 0.3em;
  height: 25%;
}
#overview ._desc strong.is-orange {
  color: #EA5504;
}
#overview ._desc strong.is-orange:after {
  background: #F9E9DA;
}
@media screen and (max-width: 900px) {
  #overview ._desc {
    font-size: 13px;
    line-height: 2;
  }
  #overview ._desc strong {
    font-size: 1.15em;
  }
}
#overview ._list1 {
  list-style-type: none;
  padding-left: 1em;
  margin-bottom: 1em;
}
#overview ._list1.is-space {
  margin-bottom: 2em;
}
#overview ._list1 li {
  font-size: 13px;
  padding: 0;
  text-indent: -1em;
  line-height: 1.2em;
  margin-bottom: 0.5em;
}
#overview ._list2 {
  margin-bottom: 1em;
}
#overview ._list2.is-space {
  margin-bottom: 2em;
}
#overview ._list2 li {
  font-size: 13px;
  line-height: 1.84615385;
  font-weight: 300;
}
#overview ._list2 li strong {
  font-weight: 500;
}
#overview ._list2.is-space {
  margin-bottom: 2em;
}
#overview ._table-scroll {
  width: 100%;
}
@media screen and (max-width: 900px) {
  #overview ._table-scroll {
    overflow: scroll;
    width: calc(100vw - 5.3333333333vw);
    margin-right: -5.3333333333vw;
  }
}
#overview ._table-wrap {
  width: 860px;
}
@media screen and (max-width: 900px) {
  #overview ._table-wrap {
    padding-bottom: 5.3333333333vw;
    padding-right: 5.3333333333vw;
  }
}
#overview table {
  width: 100%;
}
#overview table th, #overview table td {
  border: solid 2px #53977C;
  line-height: 1.46153846;
  vertical-align: middle;
  font-size: 13px;
  word-break: break-all;
  padding: 10px 5px;
}
#overview table th.is-center, #overview table td.is-center {
  text-align: center;
}
#overview table th {
  text-align: center;
  background-color: #71A58D;
  color: #fff;
}
#overview table th.is-light {
  background-color: #D2DFD6;
  color: #53977C;
}
#overview table td {
  text-align: justify;
  font-weight: 300;
}
#overview table td.is-empty {
  text-align: center;
  vertical-align: middle;
  padding-bottom: 15px !important;
}
#overview table td span {
  width: 35px;
  height: 1px;
  line-height: 1px;
  background-color: #040000;
}
#overview table tr.is-border-top-none th, #overview table tr.is-border-top-none td {
  border-top: none;
}
#overview table.is-nallow th, #overview table.is-nallow td {
  border: solid 1px #53977C;
  padding: 3px;
}
#overview ._sheet {
  background: #F0F4F2;
  border-radius: 10px;
  padding: 20px;
}
#overview ._sheet__ttl {
  font-size: 13px;
  font-weight: 500;
  color: #53977C;
  margin-bottom: 1em;
}
#overview ._sheet ._ttl-bold {
  font-size: 13px;
}
#overview ._sheet ._list1:last-child,
#overview ._sheet ._body1:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 900px) {
  #overview ._sheet {
    padding: 5.3333333333vw;
  }
}
#overview ._btn {
  width: 190px;
  height: 40px;
  background: #55402B;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  clip-path: polygon(0 50%, 5% 0, 95% 0, 100% 50%, 95% 100%, 5% 100%);
  padding-left: 0.4em;
}
#overview ._btn span {
  display: inline-block;
  margin-left: 0.4em;
  font-size: 0.7em;
  color: #FFF228;
}
#overview ._btn--info {
  width: 350px;
  height: 20px;
  font-weight: 300;
  font-size: 13px;
  clip-path: polygon(0 50%, 2% 0, 98% 0, 100% 50%, 98% 100%, 2% 100%);
}
#overview ._btn--info span {
  color: #fff;
}
#overview ._btn--dl-pdf, #overview ._btn--dl-excel {
  width: 290px;
  height: 60px;
  position: relative;
}
#overview ._btn--dl-pdf span, #overview ._btn--dl-excel span {
  color: #fff;
}
#overview ._btn--dl-pdf:before, #overview ._btn--dl-excel:before {
  content: "";
  width: 60px;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
}
#overview ._btn--dl-pdf:before {
  background: url("../img/common/icon-pdf.svg") no-repeat center center/auto 36px;
}
#overview ._btn--dl-excel:before {
  background: url("../img/common/icon-excel.svg") no-repeat center center/auto 36px;
}
@media screen and (max-width: 900px) {
  #overview ._btn--info {
    width: 100%;
    height: 5.3333333333vw;
    font-size: 13px;
  }
}
#overview ._btn-outline {
  width: 460px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: solid 1px #EA5504;
  color: #EA5504 !important;
  font-size: 18px;
}
@media screen and (max-width: 900px) {
  #overview ._btn-outline {
    width: auto;
    font-size: 14px;
    height: 9.3333333333vw;
  }
}
#overview ._info {
  width: 760px;
  margin: 0 auto;
  background: #ECF2EB;
  padding: 40px 30px;
}
#overview ._info__ttl {
  text-align: center;
  color: #53977C;
  font-size: 17px;
  margin-bottom: 1.3em;
  font-weight: 500;
  line-height: 1.6;
}
#overview ._info__body {
  font-size: 15px;
  line-height: 1.8;
}
#overview ._info__body a {
  color: #D23121 !important;
  font-weight: 300;
}
@media screen and (max-width: 900px) {
  #overview ._info {
    width: auto;
    padding: 10.6666666667vw 5.3333333333vw;
  }
  #overview ._info__ttl {
    font-size: 17px;
    margin-bottom: 1.5em;
    font-weight: 500;
  }
  #overview ._info__body {
    font-size: 13px;
  }
}
#overview ._dl {
  width: 760px;
  margin: 0 auto;
  border: dotted 3px #55402B;
  padding: 50px;
  border-radius: 10px;
}
#overview ._dl__ttl {
  text-align: center;
  color: #55402B;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 45px;
}
#overview ._dl__list {
  display: flex;
  width: 630px;
  justify-content: center;
  margin: 0 auto 45px;
}
#overview ._dl__list:last-child {
  margin-bottom: 0;
}
#overview ._dl__item {
  width: 290px;
  margin: 0 20px 0;
}
#overview ._dl__info {
  font-size: 15px;
  text-align: center;
}
#overview ._dl__info a {
  font-weight: 300;
  color: #D23121 !important;
}
@media screen and (max-width: 900px) {
  #overview ._dl {
    width: auto;
    padding: 7.2vw;
  }
  #overview ._dl__ttl {
    font-size: 16px;
    margin-bottom: 6.6666666667vw;
    line-height: 1.3;
  }
  #overview ._dl__list {
    display: block;
    width: auto;
    margin: 0 auto 9.3333333333vw;
  }
  #overview ._dl__item {
    width: auto;
    display: flex;
    justify-content: center;
    margin: 0 0 4vw 0;
  }
  #overview ._dl__info {
    font-size: 13px;
    line-height: 1.38461538;
  }
}
@media screen and (max-width: 900px) {
  #overview .sub_vis_txt .is-sp-line2 {
    height: 8.5333333333vw;
  }
}

#overview.admission .ad-nav__list {
  width: 710px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#overview.admission .ad-nav__item {
  width: 340px;
  margin-bottom: 20px;
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-nav {
    display: none;
  }
}
#overview.admission .ad-app {
  margin-bottom: 30px;
}
#overview.admission .ad-table1 {
  margin-bottom: 20px;
}
#overview.admission .ad-table2 {
  margin-bottom: 20px;
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-table2 {
    border-top: solid 2px #53977C;
  }
  #overview.admission .ad-table2 tr, #overview.admission .ad-table2 th, #overview.admission .ad-table2 td {
    display: block;
  }
  #overview.admission .ad-table2 th {
    border-top: none;
    border-bottom: none;
  }
}
#overview.admission .ad-table3 {
  margin-bottom: 20px;
}
#overview.admission .ad-price ._body1 {
  margin-bottom: 0.5em;
}
#overview.admission .ad-flow {
  width: 760px;
  margin: 0 auto 50px auto;
}
#overview.admission .ad-flow__wrap {
  display: flex;
  justify-content: space-between;
}
#overview.admission .ad-flow__half {
  width: 325px;
  position: relative;
}
#overview.admission .ad-flow__half.is-allow:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 200px;
  width: 1px;
  height: auto;
  bottom: 20px;
  background-color: #53977C;
}
#overview.admission .ad-flow__half.is-allow:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px;
  border-color: #53977C transparent transparent transparent;
  position: absolute;
  bottom: -47px;
  left: calc(50% - 18px);
  bottom: 0;
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-flow__half.is-allow:after {
    border-width: 3.2vw 1.3333333333vw;
    bottom: -6.4vw;
    left: calc(50% - 1.3333333333vw);
  }
}
#overview.admission .ad-flow__half .ad-flow__frame {
  height: 80px;
  padding: 10px;
}
#overview.admission .ad-flow__ttl {
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 1em;
  text-align: center;
}
#overview.admission .ad-flow__frame {
  background-color: #fff;
  border-radius: 10px;
  border: solid 1px #53977C;
  padding: 15px 10px;
  height: auto;
  margin-bottom: 40px;
  position: relative;
}
#overview.admission .ad-flow__frame:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px;
  border-color: #53977C transparent transparent transparent;
  position: absolute;
  bottom: -47px;
  left: calc(50% - 18px);
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-flow__frame:after {
    border-width: 3.2vw 1.3333333333vw;
    bottom: -6.4vw;
    left: calc(50% - 1.3333333333vw);
  }
}
#overview.admission .ad-flow__frame.is-none {
  margin-bottom: 0;
}
#overview.admission .ad-flow__frame.is-none:after {
  display: none;
}
#overview.admission .ad-flow__label {
  color: #53977C;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.5em;
}
#overview.admission .ad-flow__body {
  font-weight: 300;
  font-size: 13px;
  line-height: 1.46153846;
}
#overview.admission .ad-flow__wide .ad-flow__body {
  text-align: center;
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-flow {
    width: auto;
    margin: 0 auto 13.3333333333vw auto;
  }
  #overview.admission .ad-flow__half {
    width: 42.6666666667vw;
  }
  #overview.admission .ad-flow__half.is-allow:before {
    top: 26.6666666667vw;
    bottom: 5.3333333333vw;
    left: calc(50% - 0.1333333333vw);
  }
}
@media screen and (max-width: 900px) and (max-width: 600px) {
  #overview.admission .ad-flow__half.is-allow:before {
    top: 40vw;
  }
}
@media screen and (max-width: 900px) and (max-width: 450px) {
  #overview.admission .ad-flow__half.is-allow:before {
    top: 46.6666666667vw;
  }
}
@media screen and (max-width: 900px) and (max-width: 400px) {
  #overview.admission .ad-flow__half.is-allow:before {
    top: 53.3333333333vw;
  }
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-flow__half.is-allow:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px;
    border-color: #53977C transparent transparent transparent;
    position: absolute;
    bottom: -47px;
    left: calc(50% - 18px);
    bottom: -1.0666666667vw !important;
  }
}
@media screen and (max-width: 900px) and (max-width: 900px) {
  #overview.admission .ad-flow__half.is-allow:after {
    border-width: 3.2vw 1.3333333333vw;
    bottom: -6.4vw;
    left: calc(50% - 1.3333333333vw);
  }
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-flow__half .ad-flow__frame {
    height: auto;
  }
  #overview.admission .ad-flow__ttl {
    font-size: 14px;
  }
  #overview.admission .ad-flow__frame {
    padding: 2.6666666667vw;
    margin-bottom: 5.3333333333vw;
  }
  #overview.admission .ad-flow__label {
    font-size: 13px;
  }
}
#overview.admission .ad-dates {
  display: flex;
  width: 410px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
#overview.admission .ad-dates__col {
  width: 205px;
}
#overview.admission .ad-dates__ttl {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 1em;
}
#overview.admission .ad-dates__body {
  font-size: 13px;
  line-height: 2;
}
#overview.admission .ad-dates__info {
  width: 410px;
  font-size: 13px;
  line-height: 2;
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-dates {
    width: auto;
    display: block;
  }
  #overview.admission .ad-dates__col {
    width: auto;
    margin-bottom: 1.5em;
  }
  #overview.admission .ad-dates__col:last-child {
    margin-bottom: 0;
  }
  #overview.admission .ad-dates__ttl {
    margin-bottom: 0.5em;
  }
  #overview.admission .ad-dates__body {
    line-height: 1.6;
  }
  #overview.admission .ad-dates__info {
    width: auto;
    line-height: 1.5;
  }
}
#overview.admission .ad-more {
  padding: 20px;
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-more {
    font-feature-settings: "palt";
    padding: 0;
    margin-right: -1em;
    margin-top: 1.5em;
  }
  #overview.admission .ad-more ._body1 {
    line-height: 1.8;
  }
}
@media screen and (max-width: 900px) {
  #overview.admission .ad-wrap {
    margin-bottom: 0;
  }
  #overview.admission .ad-header {
    margin-bottom: 6.6666666667vw !important;
    position: relative;
  }
  #overview.admission .ad-header:after {
    content: "";
    top: calc(50% - 0.8vw);
    right: 5.3333333333vw;
    position: absolute;
    width: 0;
    height: 0;
    border-width: 13px 10px;
    border-style: solid;
    border-color: #EEBF72 transparent transparent transparent;
  }
  #overview.admission .ad-contents {
    opacity: 0;
    height: 0;
    pointer-events: none;
    transition: all 500ms cubic-bezier(0.04, 0.51, 0.155, 0.985);
    transform: translate3d(0, -10px, 0);
  }
  #overview.admission .ad-wrap.is-open .ad-contents {
    transition: all 1000ms cubic-bezier(0.04, 0.51, 0.155, 0.985);
    transform: none;
    opacity: 1;
    height: auto;
    pointer-events: auto;
  }
  #overview.admission .ad-wrap.is-open .ad-header:after {
    transform: scale(1, -1);
    top: auto;
    bottom: calc(50% - 0.8vw);
  }
}

#overview.information .if-list__item {
  border-bottom: dotted 1px #55402B;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
}
#overview.information .if-list__date {
  width: 145px;
  color: #53977C;
  font-size: 15px;
  font-weight: 500;
}
#overview.information .if-list__main {
  width: 715px;
}
#overview.information .if-list__ttl {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 1em;
}
#overview.information .if-list__body {
  font-size: 13px;
  line-height: 1.76923077;
  margin-bottom: 1em;
}
#overview.information .if-list__body:last-child {
  margin-bottom: 0;
}
#overview.information .if-list__link {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  #overview.information .if-list__item {
    display: block;
    padding: 8vw 0;
  }
  #overview.information .if-list__date {
    width: auto;
    font-size: 13px;
    margin-bottom: 0.5em;
  }
  #overview.information .if-list__main {
    width: auto;
  }
  #overview.information .if-list__ttl {
    font-size: 14px;
    line-height: 1.3;
  }
  #overview.information .if-list__link {
    display: flex;
    justify-content: center;
  }
}
#overview.information .if-pagi {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#overview.information .if-pagi__link {
  width: 35px;
  height: 35px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  color: #55402B;
  border: solid 1px #55402B;
}
#overview.information .if-pagi__link.is-current {
  background: #55402B;
  color: #fff !important;
}
#overview.information .if-pagi__next, #overview.information .if-pagi__prev {
  width: 35px;
  height: 35px;
  background: #f0f0f0;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../img/infomation/icon.svg") no-repeat center 7px/18px 40px;
}
#overview.information .if-pagi__prev {
  transform: scale(-1, 1);
}
@media screen and (max-width: 900px) {
  #overview.information .if-pagi {
    padding: 13.3333333333vw 0;
  }
  #overview.information .if-pagi__link {
    width: 9.3333333333vw;
    height: 9.3333333333vw;
    margin: 0 1.3333333333vw;
    font-size: 20px;
  }
  #overview.information .if-pagi__next, #overview.information .if-pagi__prev {
    width: 9.3333333333vw;
    height: 9.3333333333vw;
    margin: 0 1.3333333333vw;
  }
}

#overview.social .so-graph {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
#overview.social .so-graph__img {
  transform: translate3d(-30px, -33px, 0);
  width: 544px;
}
@media screen and (max-width: 900px) {
  #overview.social .so-graph {
    display: block;
    margin: 0vw 0 8vw 0;
  }
  #overview.social .so-graph__img {
    transform: translate3d(0, 0vw, 0);
    width: 89.3333333333vw;
  }
}
#overview.social .so-type {
  padding: 50px 0;
}
#overview.social .so-type__ttl {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  color: #53977C;
  text-align: center;
  margin-bottom: 2em;
}
#overview.social .so-type__ttl strong {
  text-decoration: underline;
}
#overview.social .so-type__frame {
  background: #ECF2EB;
  padding: 40px 0;
}
#overview.social .so-type__list {
  display: flex;
  justify-content: center;
}
#overview.social .so-type__item {
  background: #fff;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: solid 1px #53977C;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  font-size: 27px;
  color: #55402B;
}
@media screen and (max-width: 900px) {
  #overview.social .so-type {
    padding: 8vw 0 0vw;
  }
  #overview.social .so-type__ttl {
    font-size: 14px;
    line-height: 2.07142857;
    margin-bottom: 1.5em;
    text-align: left;
  }
  #overview.social .so-type__frame {
    padding: 8vw 0;
  }
  #overview.social .so-type__item {
    width: 24vw;
    height: 24vw;
    font-size: 16px;
    margin: 0 1.3333333333vw;
  }
}
#overview.social .so-dream__frame {
  background: #ECF2EB;
  padding: 35px 45px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
#overview.social .so-dream__prof {
  width: 222px;
}
#overview.social .so-dream__img {
  width: 156px;
  margin: 0 auto 10px;
}
#overview.social .so-dream__course {
  background: #008549;
  color: #fff;
  height: 35px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}
#overview.social .so-dream__name {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  color: #008549;
  margin-bottom: 0.3em;
}
#overview.social .so-dream__name span {
  font-size: 0.8em;
}
#overview.social .so-dream__year {
  font-weight: 500;
  color: #55402B;
  font-size: 15px;
  margin-bottom: 1em;
  font-feature-settings: "palt";
}
#overview.social .so-dream__data {
  color: #55402B;
  font-size: 13px;
  line-height: 1.38461538;
  font-weight: 300;
}
#overview.social .so-dream__body {
  width: 467px;
  text-align: justify;
}
#overview.social .so-dream__frame.is-orange {
  background: #FAEEE5;
}
#overview.social .so-dream__frame.is-orange .so-dream__name {
  color: #D23121;
}
#overview.social .so-dream__frame.is-orange .so-dream__course {
  background: #D23121;
}
@media screen and (max-width: 900px) {
  #overview.social .so-dream__frame {
    flex-direction: column;
    padding: 8vw 4vw;
    margin-bottom: 5.3333333333vw;
  }
  #overview.social .so-dream__prof {
    width: 59.2vw;
    margin-bottom: 10.6666666667vw;
  }
  #overview.social .so-dream__img {
    width: 41.6vw;
    margin: 0 auto 2.6666666667vw;
  }
  #overview.social .so-dream__course {
    height: 9.3333333333vw;
    margin-bottom: 3.4666666667vw;
  }
  #overview.social .so-dream__body {
    width: auto;
    line-height: 2;
  }
  #overview.social .so-dream__frame.is-orange {
    background: #FAEEE5;
  }
  #overview.social .so-dream__frame.is-orange .so-dream__name {
    color: #D23121;
  }
  #overview.social .so-dream__frame.is-orange .so-dream__course {
    background: #D23121;
  }
}
@media screen and (max-width: 900px) {
  #overview.social .sociel01 ._ttl {
    margin-bottom: 8vw;
  }
  #overview.social .sociel02 ._ttl {
    margin-bottom: 5.3333333333vw;
  }
}

#overview.high .high01 ._desc {
  margin-bottom: 60px;
}
@media screen and (max-width: 900px) {
  #overview.high .high01 ._ttl {
    margin-bottom: 5.3333333333vw;
  }
  #overview.high .high01 ._desc {
    margin-bottom: 10.6666666667vw;
  }
}
#overview.high .hi-pass__wrap {
  display: flex;
  width: 760px;
  margin: auto;
  justify-content: space-between;
}
#overview.high .hi-pass__item {
  width: 365px;
}
#overview.high .hi-pass__fuki {
  width: 366px;
  height: 65px;
  margin-bottom: 10px;
  background: url("../img/highSchool/fuki.svg") no-repeat center center/contain;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.66666667;
  text-align: center;
  justify-content: center;
  padding-top: 0.4em;
  color: #53977C;
}
#overview.high .hi-pass__fuki strong {
  color: #EA5504;
}
#overview.high .hi-pass__ttl {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0.5em;
  color: #53977C;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
}
#overview.high .hi-pass__body {
  margin-bottom: 1.2em;
}
@media screen and (max-width: 900px) {
  #overview.high .hi-pass__wrap {
    display: block;
    width: auto;
  }
  #overview.high .hi-pass__item {
    width: auto;
    margin-bottom: 10.6666666667vw;
  }
  #overview.high .hi-pass__item:last-child {
    margin-bottom: 0;
  }
  #overview.high .hi-pass__fuki {
    width: 89.6vw;
    height: 17.3333333333vw;
    margin-bottom: 2.6666666667vw;
    background: url("../img/highSchool/fuki-sp.svg") no-repeat center center/contain;
    font-weight: 500;
    padding-top: 0.6em;
  }
}
#overview.high .hi-load__frame {
  border: dotted 3px #53977C;
  border-radius: 5px;
  padding: 40px;
}
#overview.high .hi-load__ttl {
  width: 429px;
  margin: 0 auto 20px;
}
#overview.high .hi-load__wrap {
  width: 760px;
  padding-bottom: 5.3333333333vw;
}
#overview.high .hi-load__img {
  width: 100%;
}
@media screen and (max-width: 900px) {
  #overview.high .hi-load__frame {
    padding: 8vw 5.3333333333vw 2.6666666667vw;
  }
  #overview.high .hi-load__ttl {
    width: 75.7333333333vw;
    margin: 0 auto 8vw;
  }
  #overview.high .hi-load__scroll {
    overflow: scroll;
  }
  #overview.high .hi-load__wrap {
    width: 202.6666666667vw;
  }
}

#overview.company .company01 ._desc {
  margin-bottom: 50px;
}
@media screen and (max-width: 900px) {
  #overview.company .company01 ._desc {
    margin-bottom: 8vw;
  }
}
#overview.company .company02 ._desc {
  margin-bottom: 50px;
}
@media screen and (max-width: 900px) {
  #overview.company .company02 ._desc {
    margin-bottom: 8vw;
  }
}
#overview.company .company02 ._dl {
  margin-bottom: 50px;
}
@media screen and (max-width: 900px) {
  #overview.company .company02 ._dl {
    margin-bottom: 8vw;
  }
}

#overview.alumni ._section > section {
  margin-bottom: 70px;
}
@media screen and (max-width: 900px) {
  #overview.alumni ._section > section {
    margin-bottom: 8vw;
  }
}
#overview.alumni ._desc {
  margin-bottom: 1em;
}
#overview.alumni table {
  margin-bottom: 20px;
}
@media screen and (max-width: 900px) {
  #overview.alumni ._ttl {
    margin-bottom: 5.3333333333vw;
  }
}

#overview.line .li-mv {
  margin: 50px 0 20px;
}
@media screen and (max-width: 900px) {
  #overview.line .li-mv {
    width: 58.4vw;
    margin: 6.6666666667vw auto 9.3333333333vw;
  }
}
#overview.line .li-btn {
  background: #ECF2EB;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
#overview.line .li-btn__btn {
  width: 233px;
}
@media screen and (max-width: 900px) {
  #overview.line .li-btn {
    height: 34.1333333333vw;
  }
  #overview.line .li-btn__btn {
    width: 53.3333333333vw;
  }
}
#overview.line .li-info {
  margin-bottom: 100px;
}
#overview.line .li-info__ttl {
  width: 619px;
  margin: 0 auto 30px;
}
#overview.line .li-info__body {
  margin-bottom: 30px;
}
@media screen and (max-width: 900px) {
  #overview.line .li-info {
    margin-bottom: 8vw;
  }
  #overview.line .li-info__ttl {
    width: 83.7333333333vw;
    margin: 0 auto 9.3333333333vw;
  }
  #overview.line .li-info__body {
    margin-bottom: 6.6666666667vw;
  }
}
#overview.line .li-note {
  width: 760px;
  margin: auto;
  border: dotted 3px #55402B;
  padding: 40px 30px 30px;
  border-radius: 10px;
}
#overview.line .li-note__ttl {
  color: #55402B;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2em;
  font-size: 20px;
}
#overview.line .li-note__list {
  list-style-type: none;
  padding-left: 1em;
}
#overview.line .li-note__list li {
  font-size: 15px;
  line-height: 1.8;
  text-indent: -1em;
}
@media screen and (max-width: 900px) {
  #overview.line .li-note {
    width: auto;
    padding: 8vw 5.3333333333vw 5.3333333333vw;
  }
  #overview.line .li-note__ttl {
    font-size: 16px;
  }
  #overview.line .li-note__list li {
    font-size: 13px;
    line-height: 1.7;
  }
}

#overview.access ._ttl-green {
  margin-bottom: 2em;
}
#overview.access .ac-google {
  margin: 30px 0 80px;
}
#overview.access .ac-google iframe {
  width: 860px !important;
  height: 320px !important;
}
@media screen and (max-width: 900px) {
  #overview.access .ac-google {
    margin: 8vw 0 10.6666666667vw;
  }
  #overview.access .ac-google iframe {
    width: 89.3333333333vw !important;
    height: 56.5333333333vw !important;
  }
}
#overview.access .ac-info {
  margin-bottom: 80px;
}
#overview.access .ac-info__wrap {
  display: flex;
  justify-content: space-between;
}
#overview.access .ac-info__data {
  width: 340px;
}
#overview.access .ac-info__data ._ttl-green {
  font-size: 15px;
  font-feature-settings: "palt";
  margin-bottom: 0.8em;
}
#overview.access .ac-info__data ._body1 {
  margin-bottom: 1.8em;
}
#overview.access .ac-info__data ._body1:last-child {
  margin-bottom: 0;
}
#overview.access .ac-info__map {
  width: 520px;
}
@media screen and (max-width: 900px) {
  #overview.access .ac-info {
    margin-bottom: 12vw;
  }
  #overview.access .ac-info__wrap {
    display: block;
  }
  #overview.access .ac-info__data {
    width: auto;
  }
  #overview.access .ac-info__data ._ttl-green {
    font-size: 13px;
    margin-bottom: 0.5em;
  }
  #overview.access .ac-info__data ._body1:last-child {
    margin-bottom: 2em;
  }
  #overview.access .ac-info__map {
    width: auto;
  }
}
#overview.access .ac-link__link {
  display: block;
  width: 356px;
  margin: 0 auto 30px auto;
}
#overview.access .ac-link__note {
  text-align: center;
  font-size: 13px;
}
@media screen and (max-width: 900px) {
  #overview.access .ac-link__link {
    width: 79.2vw;
    margin: 0 auto 5.3333333333vw auto;
  }
  #overview.access .ac-link__note {
    font-size: 12px;
    line-height: 1.83333333;
  }
}

#overview.question ._wrap {
  padding: 40px 0 0 0;
}
#overview.question .qa-nav {
  width: 760px;
  margin: 70px auto 40px;
}
#overview.question .qa-nav__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}
#overview.question .qa-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #55402B;
  border: solid 1px #55402B;
  text-decoration: none;
  width: 180px;
  height: 50px;
  margin: 0 6px 12px;
  position: relative;
}
#overview.question .qa-nav a:hover {
  opacity: 0.7;
}
#overview.question .qa-nav a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center center;
}
#overview.question .qa-nav a:nth-child(1) {
  padding-left: 30px;
}
#overview.question .qa-nav a:nth-child(2) {
  padding-left: 30px;
}
#overview.question .qa-nav a:nth-child(5) {
  padding-left: 30px;
}
#overview.question .qa-nav a:nth-child(7) {
  padding-left: 30px;
}
#overview.question .qa-nav a:nth-child(1):before {
  background-image: url(../img/question/icon01.svg);
  background-size: 16px 26px;
}
#overview.question .qa-nav a:nth-child(2):before {
  background-image: url(../img/question/icon02.svg);
  background-size: 34px 19px;
}
#overview.question .qa-nav a:nth-child(3):before {
  background-image: url(../img/question/icon03.svg);
  background-size: 23px 18px;
}
#overview.question .qa-nav a:nth-child(4):before {
  background-image: url(../img/question/icon04.svg);
  background-size: 19px 23px;
}
#overview.question .qa-nav a:nth-child(5):before {
  background-image: url(../img/question/icon05.svg);
  background-size: 24px 24px;
}
#overview.question .qa-nav a:nth-child(6):before {
  background-image: url(../img/question/icon06.svg);
  background-size: 27px 27px;
}
#overview.question .qa-nav a:nth-child(7):before {
  background-image: url(../img/question/icon07.svg);
  background-size: 17px 27px;
}
#overview.question .qa-nav a:nth-child(8):before {
  background-image: url(../img/question/icon08.svg);
  background-size: 22px 24px;
}
@media screen and (max-width: 900px) {
  #overview.question .qa-nav {
    width: 89.3333333333vw;
    margin: 10.6666666667vw auto;
  }
  #overview.question .qa-nav__wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.6vw;
  }
  #overview.question .qa-nav a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    width: 42.6666666667vw;
    height: 12vw;
    margin: 0 1.6vw 3.2vw;
  }
  #overview.question .qa-nav a:hover {
    opacity: 1;
  }
  #overview.question .qa-nav a:before {
    width: 12vw;
  }
  #overview.question .qa-nav a:nth-child(1) {
    padding-left: 8vw;
  }
  #overview.question .qa-nav a:nth-child(2) {
    padding-left: 8vw;
  }
  #overview.question .qa-nav a:nth-child(5) {
    padding-left: 8vw;
  }
  #overview.question .qa-nav a:nth-child(7) {
    padding-left: 8vw;
  }
}
#overview.question .qa-main {
  background: #ECF2EB;
  padding: 40px 0 60px;
  min-height: 500px;
}
#overview.question .qa-main__wrap {
  width: 860px;
  margin: auto;
}
#overview.question .qa-main__ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #55402B;
  margin-bottom: 40px;
}
#overview.question .qa-main__ttl img {
  width: 20px;
  margin-right: 10px;
}
#overview.question .qa-main__list {
  background: #fff;
  border-top: solid 1px #53977C;
}
#overview.question .qa-main__item {
  border-bottom: solid 1px #53977C;
}
#overview.question .qa-main__q, #overview.question .qa-main__a {
  padding: 0 20px 0 20px;
  display: flex;
  position: relative;
}
#overview.question .qa-main__q i, #overview.question .qa-main__a i {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  width: 30px;
  height: 30px;
  font-style: normal;
  font-size: 24px;
  border-radius: 50%;
  margin-right: 20px;
}
#overview.question .qa-main__q span, #overview.question .qa-main__a span {
  font-size: 13px;
  line-height: 1.76923077;
}
#overview.question .qa-main__q {
  height: 80px;
  align-items: center;
  cursor: pointer;
}
#overview.question .qa-main__q:hover {
  transition: all 200ms cubic-bezier(0.04, 0.51, 0.155, 0.985);
  opacity: 0.7;
}
#overview.question .qa-main__q i {
  font-weight: 700;
  color: #53977C;
  background: #fff;
}
#overview.question .qa-main__q:before, #overview.question .qa-main__q:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  background: #53977C;
  right: 20px;
  top: 50%;
}
#overview.question .qa-main__q:after {
  transition: all 300ms cubic-bezier(0.04, 0.51, 0.155, 0.985);
  transform-origin: center center;
  transform: rotate(90deg);
}
#overview.question .qa-main__a {
  align-items: flex-start;
  padding-bottom: 0em;
  opacity: 0;
  pointer-events: none;
  height: 0;
  transform: translate3d(0, -10px, 0);
  transition: all 300ms cubic-bezier(0.04, 0.51, 0.155, 0.985);
}
#overview.question .qa-main__a i {
  font-weight: 700;
  background: #55402B;
  color: #fff !important;
}
#overview.question .qa-main__item.is-open .qa-main__q:after {
  transform: none;
}
#overview.question .qa-main__item.is-open .qa-main__a {
  opacity: 1;
  pointer-events: auto;
  height: auto;
  transform: none;
  padding-bottom: 2em;
}
@media screen and (max-width: 900px) {
  #overview.question .qa-main {
    padding: 6.6666666667vw 5.3333333333vw 10.6666666667vw 5.3333333333vw;
  }
  #overview.question .qa-main__wrap {
    width: auto;
  }
  #overview.question .qa-main__ttl {
    font-size: 18px;
    margin-bottom: 8vw;
  }
  #overview.question .qa-main__ttl img {
    width: 12vw;
  }
  #overview.question .qa-main__q, #overview.question .qa-main__a {
    padding: 0 4vw 0 2.6666666667vw;
  }
  #overview.question .qa-main__q i, #overview.question .qa-main__a i {
    width: 8vw;
    height: 8vw;
    font-size: 24px;
    margin-right: 2.6666666667vw;
  }
  #overview.question .qa-main__q {
    height: 21.3333333333vw;
  }
  #overview.question .qa-main__q:before, #overview.question .qa-main__q:after {
    width: 4vw;
    right: 2.6666666667vw;
  }
}
#overview.question .qa-main__contents {
  opacity: 0;
  height: 0;
  pointer-events: none;
  transition: all 300ms cubic-bezier(0.04, 0.51, 0.155, 0.985);
}
#overview.question .qa-main__contents[data-index="1"] .qa-main__ttl img {
  width: 16px;
}
#overview.question .qa-main__contents[data-index="2"] .qa-main__ttl img {
  width: 34px;
}
#overview.question .qa-main__contents[data-index="3"] .qa-main__ttl img {
  width: 23px;
}
#overview.question .qa-main__contents[data-index="4"] .qa-main__ttl img {
  width: 19px;
}
#overview.question .qa-main__contents[data-index="5"] .qa-main__ttl img {
  width: 24px;
}
#overview.question .qa-main__contents[data-index="6"] .qa-main__ttl img {
  width: 27px;
}
#overview.question .qa-main__contents[data-index="7"] .qa-main__ttl img {
  width: 17px;
}
#overview.question .qa-main__contents[data-index="8"] .qa-main__ttl img {
  width: 22px;
}
#overview.question[data-index="1"] .qa-main__contents[data-index="1"], #overview.question[data-index="2"] .qa-main__contents[data-index="2"], #overview.question[data-index="3"] .qa-main__contents[data-index="3"], #overview.question[data-index="4"] .qa-main__contents[data-index="4"], #overview.question[data-index="5"] .qa-main__contents[data-index="5"], #overview.question[data-index="6"] .qa-main__contents[data-index="6"], #overview.question[data-index="7"] .qa-main__contents[data-index="7"], #overview.question[data-index="8"] .qa-main__contents[data-index="8"] {
  opacity: 1;
  height: auto;
  pointer-events: auto;
  transform: none;
}
#overview.question[data-index="1"] .qa-nav a[data-index="1"], #overview.question[data-index="2"] .qa-nav a[data-index="2"], #overview.question[data-index="3"] .qa-nav a[data-index="3"], #overview.question[data-index="4"] .qa-nav a[data-index="4"], #overview.question[data-index="5"] .qa-nav a[data-index="5"], #overview.question[data-index="6"] .qa-nav a[data-index="6"], #overview.question[data-index="7"] .qa-nav a[data-index="7"], #overview.question[data-index="8"] .qa-nav a[data-index="8"] {
  background: #55402B;
  color: #fff !important;
}
#overview.question[data-index="1"] .qa-nav a[data-index="1"]:before {
  background-image: url(../img/question/icon01-w.svg);
}
#overview.question[data-index="2"] .qa-nav a[data-index="2"]:before {
  background-image: url(../img/question/icon02-w.svg);
}
#overview.question[data-index="3"] .qa-nav a[data-index="3"]:before {
  background-image: url(../img/question/icon03-w.svg);
}
#overview.question[data-index="4"] .qa-nav a[data-index="4"]:before {
  background-image: url(../img/question/icon04-w.svg);
}
#overview.question[data-index="5"] .qa-nav a[data-index="5"]:before {
  background-image: url(../img/question/icon05-w.svg);
}
#overview.question[data-index="6"] .qa-nav a[data-index="6"]:before {
  background-image: url(../img/question/icon06-w.svg);
}
#overview.question[data-index="7"] .qa-nav a[data-index="7"]:before {
  background-image: url(../img/question/icon07-w.svg);
}
#overview.question[data-index="8"] .qa-nav a[data-index="8"]:before {
  background-image: url(../img/question/icon08-w.svg);
}

#overview.eva ._dl {
  margin: 40px auto;
}

#opencampus {
  font-weight: 300;
  color: #040000;
  font-family: "Noto Sans JP", sans-serif;
  background: #F0F4F2;
  padding-bottom: 100px;
}
#opencampus img {
  width: 100%;
}
@media screen and (max-width: 900px) {
  #opencampus {
    width: 100vw;
    overflow: hidden;
  }
}
#opencampus ._wrap {
  padding: 0;
  width: 860px;
  margin: auto;
}
@media screen and (max-width: 900px) {
  #opencampus ._wrap {
    width: 89.3333333333vw;
  }
}
#opencampus br._sp {
  display: none;
}
@media screen and (max-width: 900px) {
  #opencampus br._sp {
    display: inline;
  }
}
#opencampus ._btn {
  width: 190px;
  height: 40px;
  background: #55402B;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  clip-path: polygon(0 50%, 5% 0, 95% 0, 100% 50%, 95% 100%, 5% 100%);
  padding-left: 0.4em;
}
#opencampus ._btn span {
  display: inline-block;
  margin-left: 0.4em;
  font-size: 0.7em;
  color: #FFF228;
}
#opencampus .oc-head {
  width: 1080px;
  max-width: 100%;
  margin: auto;
  height: 979px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-head {
    height: 230.9333333333vw;
  }
}
#opencampus .oc-logo {
  position: absolute;
  top: 40px;
  left: -81px;
  width: 796px;
  z-index: 1;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-logo {
    width: 96vw;
    top: 5.3333333333vw;
    left: 1.8666666667vw;
  }
}
#opencampus .oc-student {
  width: 707px;
  position: absolute;
  top: 160px;
  right: -152px;
  z-index: 2;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-student {
    width: 90.4vw;
    top: 63.4666666667vw;
    left: 16.2666666667vw;
  }
}
#opencampus .oc-copy {
  position: absolute;
  left: 99px;
  bottom: 120px;
  width: 499px;
  z-index: 3;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-copy {
    width: 86.9333333333vw;
    bottom: auto;
    top: 150.6666666667vw;
    left: 6.9333333333vw;
  }
}
#opencampus .oc-sheet {
  z-index: 1;
  width: 1080px;
  max-width: 100%;
  background: #fff;
  margin: auto;
  position: relative;
}
#opencampus .oc-sheet:before {
  content: "";
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: -150px;
  height: 150px;
  clip-path: polygon(0 100%, 50% 0, 100% 100%);
}
@media screen and (max-width: 900px) {
  #opencampus .oc-sheet {
    width: 94.6666666667vw;
  }
  #opencampus .oc-sheet:before {
    top: -13.52544vw;
    height: 13.52544vw;
  }
}
#opencampus .oc-schedule {
  position: relative;
}
#opencampus .oc-schedule__head {
  width: 173px;
  height: 80px;
  margin: 0 auto 0px auto;
  transform: translate3d(0, -71px, 0);
}
#opencampus .oc-schedule__list {
  display: flex;
  margin: 0 -10px 0 -10px;
  flex-wrap: wrap;
}
#opencampus .oc-schedule__item {
  width: 420px;
  margin: 0px 10px 20px 10px;
  background: #D9ECF4;
  border-radius: 15px;
  padding: 20px 5px 20px 60px;
  position: relative;
}
#opencampus .oc-schedule__item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  background: url("../img/openCampus/icon.svg") no-repeat center center/23px 26px;
}
#opencampus .oc-schedule__date {
  font-weight: 700;
  color: #53977C;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 0.5em;
}
#opencampus .oc-schedule__time {
  font-weight: 700;
  color: #55402B;
  font-size: 15px;
  line-height: 1;
  margin-bottom: 1.5em;
}
#opencampus .oc-schedule__ttl {
  font-weight: 700;
  color: #55402B;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 0.5em;
  font-feature-settings: "palt";
  letter-spacing: 0.1em;
}
#opencampus .oc-schedule__copy {
  font-weight: 700;
  color: #55402B;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 0;
}
#opencampus .oc-schedule__link {
  padding: 50px 0 75px 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-schedule__head {
    width: 25.3333333333vw;
    height: 17.6vw;
    transform: translate3d(0, -5.3333333333vw, 0);
  }
  #opencampus .oc-schedule__list {
    display: block;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  #opencampus .oc-schedule__item {
    width: auto;
    margin: 0px 0 2.6666666667vw 0;
    border-radius: 4vw;
    padding: 5.3333333333vw 2.6666666667vw;
  }
  #opencampus .oc-schedule__item:before {
    display: none;
  }
  #opencampus .oc-schedule__ttl {
    font-size: 16px;
  }
  #opencampus .oc-schedule__copy {
    font-size: 12px;
  }
  #opencampus .oc-schedule__link {
    padding: 8vw 0 16vw 0;
  }
}
#opencampus .oc-voice {
  border-radius: 10px;
  border: dotted 3px #53977C;
  padding: 50px;
  margin-bottom: 70px;
}
#opencampus .oc-voice__head {
  width: 175px;
  margin: 0 auto 30px;
}
#opencampus .oc-voice__list {
  list-style-type: none;
}
#opencampus .oc-voice__item {
  background: #E1F0F7;
  border-radius: 5px;
  padding: 20px 30px;
  margin-bottom: 18px;
}
#opencampus .oc-voice__label {
  background: #53977C;
  border-radius: 8px;
  width: 130px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  padding: 0 1.5em;
  color: #fff;
  margin-bottom: 0.3em;
}
#opencampus .oc-voice__body {
  font-size: 15px;
  line-height: 1.73333333;
}
#opencampus .oc-voice__imgs {
  display: flex;
  justify-content: space-between;
}
#opencampus .oc-voice__img {
  width: 247px;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-voice {
    width: 89.3333333333vw;
    border-radius: 2.6666666667vw;
    border: dotted 2px #53977C;
    padding: 5.3333333333vw 5.3333333333vw 5.3333333333vw;
    margin: 0 auto 13.3333333333vw;
  }
  #opencampus .oc-voice__head {
    width: 46.6666666667vw;
    margin: 0 auto 9.3333333333vw;
  }
  #opencampus .oc-voice__item {
    padding: 5.3333333333vw 6.6666666667vw;
    margin-bottom: 2.6666666667vw;
  }
  #opencampus .oc-voice__item:last-child {
    margin-bottom: 8vw;
  }
  #opencampus .oc-voice__body {
    font-size: 13px;
  }
  #opencampus .oc-voice__body br {
    display: none;
  }
  #opencampus .oc-voice__imgs {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -2.1333333333vw;
  }
  #opencampus .oc-voice__img {
    width: 37.3333333333vw;
    margin: 0 1.6vw 3.2vw;
  }
}
#opencampus .oc-flow__ttl {
  background-color: #55402B;
  color: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.2em;
  font-feature-settings: normal;
  font-size: 22px;
  border-radius: 4px;
  line-height: 1.68181818;
  margin-bottom: 40px;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-flow__ttl {
    height: 70px;
    font-size: 16px;
  }
}
#opencampus .oc-flow__desc {
  line-height: 1.5em;
  font-size: 15px;
  margin-bottom: 3em;
}
#opencampus .oc-flow__list {
  width: 750px;
  margin: auto;
}
#opencampus .oc-flow__item {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}
#opencampus .oc-flow__item:last-child {
  margin-bottom: 0;
}
#opencampus .oc-flow__item:last-child .oc-flow__body:after {
  display: none;
}
#opencampus .oc-flow__circle {
  font-weight: 500;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #85A44F;
  color: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
#opencampus .oc-flow__body {
  width: 650px;
  position: relative;
}
#opencampus .oc-flow__body:after {
  content: "";
  position: absolute;
  left: 250px;
  bottom: -80px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px;
  border-color: #BFC45F transparent transparent transparent;
}
#opencampus .oc-flow__body-ttl {
  font-weight: 500;
  line-height: 1;
  font-size: 18px;
  margin-bottom: 1em;
}
#opencampus .oc-flow__body-ttl:last-child {
  margin-bottom: 0;
}
#opencampus .oc-flow__body-list {
  list-style-type: none;
  padding-left: 1em;
}
#opencampus .oc-flow__body-list li {
  padding: 0;
  text-indent: -1em;
  line-height: 1.5;
  font-size: 15px;
}
#opencampus .oc-flow__time {
  width: 260px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
#opencampus .oc-flow__time-head {
  width: 130px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.61111111;
}
#opencampus .oc-flow__time-body {
  width: 130px;
  font-size: 18px;
  line-height: 1.61111111;
}
#opencampus .oc-flow__link {
  display: flex;
  justify-content: center;
  padding: 45px 0 70px 0;
}
@media screen and (max-width: 900px) {
  #opencampus .oc-flow__desc {
    font-size: 13px;
  }
  #opencampus .oc-flow__list {
    width: auto;
  }
  #opencampus .oc-flow__item {
    margin-bottom: 17.6vw;
    align-items: flex-start;
  }
  #opencampus .oc-flow__circle {
    font-size: 17.5px;
    width: 18.6666666667vw;
    height: 18.6666666667vw;
    margin-right: 2.6666666667vw;
  }
  #opencampus .oc-flow__body {
    width: 68vw;
    padding-top: 4vw;
  }
  #opencampus .oc-flow__body:after {
    left: 18.6666666667vw;
    bottom: -18.6666666667vw;
    border-width: 5.8666666667vw;
  }
  #opencampus .oc-flow__body-ttl {
    font-size: 14px;
    line-height: 1.7;
    margin-right: -2em;
  }
  #opencampus .oc-flow__body-list {
    list-style-type: none;
    padding-left: 1em;
    margin-left: -21.3333333333vw;
  }
  #opencampus .oc-flow__body-list li {
    padding: 0;
    text-indent: -1em;
    line-height: 1.5;
    font-size: 13px;
  }
  #opencampus .oc-flow__time {
    width: 69.3333333333vw;
    margin-top: -2.6666666667vw;
  }
  #opencampus .oc-flow__time-head {
    width: 34.6666666667vw;
  }
  #opencampus .oc-flow__time-body {
    width: 34.6666666667vw;
  }
  #opencampus .oc-flow__link {
    padding: 8vw 0 12vw 0;
  }
}