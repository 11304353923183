#overview.access
	
	._ttl-green
		margin-bottom: 2em
	.ac-google
		margin: 30px 0 80px
		iframe
			width: 860px !important
			height: 320px !important
		+mq()
			margin: px2vwSP(30) 0 px2vwSP(40)
			iframe
				width: px2vwSP(335) !important
				height: px2vwSP(212) !important
	.ac-info
		margin-bottom: 80px
		&__wrap
			display: flex
			justify-content: space-between
		&__data
			width: 340px
			._ttl-green
				font-size: 15px
				font-feature-settings: 'palt'
				margin-bottom: 0.8em
			._body1
				margin-bottom: 1.8em
				&:last-child
					margin-bottom: 0
		&__map
			width: 520px
		+mq()
			margin-bottom: px2vwSP(45)
			&__wrap
				display: block
			&__data
				width: auto
				._ttl-green
					font-size: 13px
					margin-bottom: 0.5em
				._body1:last-child
					margin-bottom: 2em
			&__map
				width: auto		
	.ac-link
		&__link
			display: block
			width: 356px
			margin: 0 auto 30px auto
		&__note
			text-align: center
			font-size: 13px
		+mq()
			&__link
				width: px2vwSP(297)
				margin: 0 auto px2vwSP(20) auto
			&__note
				font-size: 12px
				line-height: 1.83333333
				
		
			
				
						