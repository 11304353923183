#overview.high

	.high01 
		._desc
			margin-bottom: 60px
		._ttl-outline
		+mq()
			._ttl
				margin-bottom: px2vwSP(20)
			._desc
				margin-bottom: px2vwSP(40)
	.hi-pass
		&__wrap
			display: flex
			width: 760px
			margin: auto
			justify-content: space-between
		&__item
			width: 365px
		&__fuki
			width: 366px
			height: 65px
			margin-bottom: 10px
			background: url("../img/highSchool/fuki.svg") no-repeat center center / contain
			+fwMedium
			font-size: 12px
			line-height: 1.66666667
			text-align: center
			justify-content: center
			padding-top: 0.4em
			color: $color-txt-green
			strong
				color: $color-txt-orange
		&__table
		&__ttl
			font-size: 15px
			+fwBold
			margin-bottom: 0.5em
			color: $color-txt-green
			+letterSpace
		&__body
			margin-bottom: 1.2em
		+mq()
			&__wrap
				display: block
				width: auto
			&__item
				width: auto
				margin-bottom: px2vwSP(40)
				&:last-child
					margin-bottom: 0
			&__fuki
				width: px2vwSP(336)
				height: px2vwSP(65)
				margin-bottom: px2vwSP(10)
				background: url("../img/highSchool/fuki-sp.svg") no-repeat center center / contain
				+fwMedium
				padding-top: 0.6em
			&__table
			
			
				
	.hi-load
		&__frame
			border: dotted 3px $color-green
			border-radius: 5px
			padding: 40px
		&__ttl
			width: 429px
			margin: 0 auto 20px
		&__scroll
		&__wrap
			width: 760px
			padding-bottom: px2vwSP(20)
		&__img
			width: 100%
		+mq()
			&__frame
				padding: px2vwSP(30) px2vwSP(20) px2vwSP(10)
			&__ttl
				width: px2vwSP(284)
				margin: 0 auto px2vwSP(30)
			&__scroll
				overflow: scroll
			&__wrap
				width: px2vwSP(760)
			&__img
						