@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300,400,500,700&display=swap')

=fwBold
	font-weight: 700
=fwMedium
	font-weight: 500
=fwRegular
	font-weight: 400
=fwLight
	font-weight: 300

$color-mos-green: #008549
$color-green: #53977C
$color-green-lighten: #71A58D
$color-green-lighten2: #D2DFD6
$color-green-lighten3: #E2EBE0
$color-green-lighten4: #F0F4F2

$color-yellow: #FFF228
$color-yellow-green: #85A44F
$color-yellow-green-lighten: #BFC45F
$color-blue-lighten1: #D9ECF4
$color-blue-lighten2: #ECF2EB
$color-blue-lighten3: #E1F0F7

$color-gold: #EEBF72

$color-orange: #EA5504
$color-orange-lighten: #F9E9DA
$color-orange-lighten2: #FAEEE5
$color-red: #D23121

	
$color-brown: #55402B


$color-bg-brown: $color-brown
$color-bg-green: $color-green
$color-bg-green-light: $color-green-lighten4

$color-txt-yellow: $color-yellow
$color-txt-green: $color-green
$color-txt-orange: $color-orange
$color-txt-brown: $color-brown
$color-txt: #040000

=letterSpace
	letter-spacing: 0.2em
	font-feature-settings: normal
=contentWrap
	+w($contentWidth)
	margin: auto
	+mq()
		width: 100vw

=absoluteCover
	position: absolute
	top: 0
	left: 0
	bottom: 0
	right: 0
=fixCover
	position: fixed
	top: 0
	left: 0
	bottom: 0
	right: 0
=fixCover
	position: fixed
	top: 0
	left: 0
	bottom: 0
	right: 0
=flexCenter
	display: flex
	justify-content: center
	align-items: center

$breakPointSmartPhone: 900
$designWidthPc: 900
$designWidthSp: 375
	
//----------------------------------------------------------------breakpoint
@function px2vw($num)
	@if $num == 'auto'
		@return auto
	@else
		@return #{$num/$designWidthPc*100}vw

@function px2vwPC($num)
	@if $num == 'auto'
		@return auto
	@else
		@return #{$num}px

@function px2vwSP($num)
	@if $num == 'auto'
		@return auto
	@else
		@return #{$num/$designWidthSp*100}vw

=mq($breakpoint:$breakPointSmartPhone)
	@media screen and (max-width: #{$breakpoint}px)
		@content
=mqMin($breakpoint:$designWidthPc)
	@media screen and (min-width: #{$breakpoint}px)
		@content
//-------------------------------------------------------------------util


//fontsize
=fz($num)
	font-size: px2vw($num)
	font-size: unquote('max(#{px2vw($num)},12px)')
//box-size
=w($num)
	width: px2vw($num)
	max-width: ($num)+px
=h($num)
	height: px2vw($num)
	max-height: ($num)+px

//padding and margins		
=p($pt:0,$pr:0,$pb:0,$pl:0)
	padding: px2vw($pt) px2vw($pr) px2vw($pb) px2vw($pl)
	+mqMin()
		padding: px2vwPC($pt) px2vwPC($pr) px2vwPC($pb) px2vwPC($pl)
=pt($p:0)
	padding-top: px2vw($p)
	+mqMin()
		padding-top: #{$p}px
=pr($p:0)
	padding-right: px2vw($p)
	+mqMin()
		padding-right: #{$p}px
=pb($p:0)
	padding-bottom: px2vw($p)
	+mqMin()
		padding-bottom: #{$p}px
=pl($p:0)
	padding-left: px2vw($p)
	+mqMin()
		padding-left: #{$p}px

=m($mt:0,$mr:0,$mb:0,$ml:0)
	margin: px2vw($mt) px2vw($mr) px2vw($mb) px2vw($ml)
	+mqMin()
		margin: px2vwPC($mt) px2vwPC($mr) px2vwPC($mb) px2vwPC($ml)
=mt($m:0)
	margin-top: px2vw($m)
	+mqMin()
		margin-top: #{$m}px
=mr($m:0)
	margin-right: px2vw($m)
	+mqMin()
		margin-right: #{$m}px
=mb($m:0)
	margin-bottom: px2vw($m)
	+mqMin()
		margin-bottom: #{$m}px
=ml($m:0)
	margin-left: px2vw($m)
	+mqMin()
		margin-left: #{$m}px

