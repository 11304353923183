#overview
	color: $color-txt
	font-family: "Noto Sans JP", sans-serif
	+fwLight
	+mq()
		width: 100vw 
		overflow: hidden
	p,li
		a
			+fwBold
			color: $color-txt-green !important
			text-decoration: underline

	._link-icon
		display: inline-flex
		align-items: center
		text-decoration: none
		+fwLight
		margin-right: 0.3em
		&:after
			content: ''
			width: 1em
			height: 1em
			background: url("../img/common/icon-link.svg") no-repeat center center / contain
	br._sp
		display: none
		+mq()
			display: inline
	._palt
		font-feature-settings: "palt"
	._wrap
		padding: 100px 0 150px 0
		width: 860px
		margin: auto
		+mq()
			width: px2vwSP(335)
			padding: px2vwSP(30) 0 px2vwSP(50) 0

	._section
		margin-bottom: 50px
		&.is-space01
			margin-bottom: 75px
		&.is-space02
			margin-bottom: 100px
		+mq()
			&.is-space01
				margin-bottom: px2vwSP(30)
			&.is-space02
				margin-bottom: px2vwSP(60)

	._ttl
		background-color: $color-bg-green
		color: #fff
		min-height: 80px
		+flexCenter
		+fwMedium
		+letterSpace
		font-size: 22px
		border-radius: 4px
		line-height: 1.68181818
		margin-bottom: 40px
		text-align: center
		padding: 1em 0
		&.is-space
			margin-bottom: 80px
		+mq()
			min-height: 70px
			font-size: 16px
	._ttl-label
		+flexCenter
		+fwMedium
		display: inline-flex
		height: 28px
		font-size: 15px
		+letterSpace
		border: solid 1px $color-brown
		color: $color-brown
		padding: 0 0.7em 0 0.9em
		margin-bottom: 1em
		
	._ttl-bold
		+fwMedium
		font-size: 15px
		+letterSpace
		margin-bottom: 1em
		+mq()
			line-height: 1.3
			font-size: 13px
	._ttl-green
		+fwMedium
		color: $color-txt-green
		+letterSpace
		font-size: 20px
		margin-bottom: 1em
		+mq()
			line-height: 1.3
			font-size: 15px
	._ttl-outline
		font-size: 20px
		+letterSpace
		border: solid 1px $color-brown
		color: $color-txt-orange
		+flexCenter
		min-height: 55px
		margin-bottom: 40px
		+mq()
			letter-spacing: 0
			font-size: 16px
			min-height: px2vwSP(55)
			
			
	._body1
		font-size: 13px
		line-height: 2.15384615
		margin-bottom: 1em
		&.is-space
			margin-bottom: 2em
	._body2
		font-size: 12px
		line-height: 1.66666667
		margin-bottom: 1em
		&.is-space
			margin-bottom: 2em
	._body3
		font-size: 13px
		line-height: 1.5em
		margin-bottom: 1em
		&.is-space
			margin-bottom: 2em
	._desc
		+fwLight
		font-size: 15px
		line-height: 2.2
		strong
			position: relative
			display: inline-block
			color: $color-txt-green
			font-size: 1.3em
			font-feature-settings: 'palt'
			letter-spacing: 0.1em
			&:after
				content: ''
				position: absolute
				z-index: -1
				background: $color-green-lighten3
				left: 0
				right: 0
				bottom: 0.3em
				height: 25%
			&.is-orange
				color: $color-txt-orange
				&:after
					background: $color-orange-lighten
				
		+mq()
			font-size: 13px
			line-height: 2
			strong
				font-size: 1.15em
		
	._list1
		list-style-type: none
		padding-left: 1em
		margin-bottom: 1em
		&.is-space
			margin-bottom: 2em
		li
			font-size: 13px
			padding: 0
			text-indent: -1em
			line-height: 1.2em
			margin-bottom: 0.5em
	._list2
		margin-bottom: 1em
		&.is-space
			margin-bottom: 2em
		li
			font-size: 13px
			line-height: 1.84615385
			+fwLight
			strong
				+fwMedium
		&.is-space
			margin-bottom: 2em
	._table-scroll
		width: 100%
		+mq()
			overflow: scroll
			width: calc(100vw - px2vwSP(20))
			margin-right: px2vwSP(-20)
	._table-wrap
		width: 860px
		+mq()
			padding-bottom: px2vwSP(20)
			padding-right: px2vwSP(20)
	table
		width: 100%

		th, td
			border: solid 2px $color-green
			line-height: 1.46153846
			vertical-align: middle
			font-size: 13px
			word-break: break-all
			padding: 10px 5px

			&.is-center
				text-align: center

		th
			text-align: center
			background-color: $color-green-lighten
			color: #fff

			&.is-light
				background-color: $color-green-lighten2
				color: $color-txt-green

		td
			text-align: justify
			+fwLight

			&.is-empty
				text-align: center
				vertical-align: middle
				padding-bottom: 15px !important
			span
				width: 35px
				height: 1px
				line-height: 1px
				background-color: $color-txt

		tr.is-border-top-none
			th, td
				border-top: none
		&.is-nallow
			th, td
				border: solid 1px $color-green
				padding: 3px
			
	._sheet
		background: $color-bg-green-light
		border-radius: 10px
		padding: 20px
		&__ttl
			font-size: 13px
			+fwMedium
			color: $color-txt-green
			margin-bottom: 1em
		._ttl-bold
			font-size: 13px
		._list1:last-child,
		._body1:last-child
			margin-bottom: 0
		+mq()
			padding: px2vwSP(20)
	._btn
		width: 190px
		height: 40px
		background: $color-bg-brown
		color: #fff
		display: flex
		justify-content: center
		align-items: center
		+fwBold
		font-size: 14px
		clip-path: polygon(0 50%,5% 0,95% 0,100% 50%,95% 100%,5% 100%)
		padding-left: 0.4em
		span
			display: inline-block
			margin-left: 0.4em
			font-size: 0.7em
			color: $color-txt-yellow			
		&--info
			width: 350px
			height: 20px
			+fwLight
			font-size: 13px
			clip-path: polygon(0 50%,2% 0,98% 0,100% 50%,98% 100%,2% 100%)
			span
				color: #fff
		&--dl-pdf,
		&--dl-excel
			width: 290px
			height: 60px
			position: relative
			span
				color: #fff
			&:before
				content: ''
				width: 60px
				position: absolute
				left: 15px
				top: 0
				bottom: 0
		&--dl-pdf:before
			background: url("../img/common/icon-pdf.svg") no-repeat center center / auto 36px
		&--dl-excel:before
			background: url("../img/common/icon-excel.svg") no-repeat center center / auto 36px
		+mq()
			&--info
				width: 100%
				height: px2vwSP(20)
				font-size: 13px
	._btn-outline
		width: 460px
		height: 50px
		+flexCenter
		border-radius: 999px
		border: solid 1px $color-orange
		color: $color-txt-orange !important
		font-size: 18px
		+mq()
			width: auto
			font-size: 14px
			height: px2vwSP(35)
	._info
		width: 760px
		margin: 0 auto
		background: $color-blue-lighten2
		padding: 40px 30px
		&__ttl
			text-align: center
			color: $color-txt-green
			font-size: 17px
			margin-bottom: 1.3em
			+fwMedium
			line-height: 1.6
		&__body
			font-size: 15px
			line-height: 1.8
			a
				color: $color-red !important
				+fwLight
		+mq()
			width: auto
			padding: px2vwSP(40) px2vwSP(20)
			&__ttl
				font-size: 17px
				margin-bottom: 1.5em
				+fwMedium
			&__body
				font-size: 13px
			
	._dl
		width: 760px
		margin: 0 auto
		border: dotted 3px $color-brown
		padding: 50px
		border-radius: 10px
		&__ttl
			text-align: center
			color: $color-txt-brown
			+letterSpace
			font-size: 20px
			+fwMedium
			margin-bottom: 45px
		&__list
			display: flex
			width: 630px
			justify-content: center
			margin: 0 auto 45px
			&:last-child
				margin-bottom: 0
		&__item
			width: 290px
			margin: 0 20px 0
		&__info
			font-size: 15px
			text-align: center
			a
				+fwLight
				color: $color-red !important
		+mq()
			width: auto
			padding: px2vwSP(27)
			&__ttl
				font-size: 16px
				margin-bottom: px2vwSP(25)
				line-height: 1.3
			&__list
				display: block
				width: auto
				margin: 0 auto px2vwSP(35)
			&__item
				width: auto
				display: flex
				justify-content: center
				margin: 0 0 px2vwSP(15) 0
			&__info
				font-size: 13px
				line-height: 1.38461538

	.sub_vis_txt .is-sp-line2
		+mq()
			height: px2vwSP(32)
