#overview.admission
	.ad-nav
		&__list
			width: 710px
			margin: auto
			display: flex
			justify-content: space-between
			flex-wrap: wrap

		&__item
			width: 340px
			margin-bottom: 20px

		+mq()
			display: none
			
	.ad-app
		margin-bottom: 30px

	.ad-table1
		margin-bottom: 20px

	.ad-table2
		margin-bottom: 20px
		+mq()
			border-top: solid 2px $color-green
			tr,th,td
				display: block
			th
				border-top: none
				border-bottom: none
				
	.ad-table3
		margin-bottom: 20px

	.ad-price
		._body1
			margin-bottom: 0.5em

	=ad-flow-arrow
		content: ''
		width: 0
		height: 0
		border-style: solid
		border-width: 18px
		border-color: $color-green transparent transparent transparent
		position: absolute
		bottom: -47px
		left: calc(50% - 18px)
		+mq()
			border-width: px2vwSP(12) px2vwSP(5)
			bottom: px2vwSP(-24)
			left: calc(50% - #{px2vwSP(5)})
			
		
	
	.ad-flow
		width: 760px
		margin: 0 auto 50px auto

		&__wrap
			display: flex
			justify-content: space-between

		&__half
			width: 325px
			position: relative
			&.is-allow
				&:before
					content: ''
					position: absolute
					left: 50%
					top: 200px
					width: 1px
					height: auto
					bottom: 20px
					background-color: $color-green

				&:after
					+ad-flow-arrow
					bottom: 0
			.ad-flow__frame
				height: 80px
				padding: 10px
		&__ttl
			font-size: 17px
			line-height: 1
			+fwMedium
			margin-bottom: 1em
			text-align: center

		&__frame
			background-color: #fff
			border-radius: 10px
			border: solid 1px $color-green
			padding: 15px 10px
			height: auto
			margin-bottom: 40px
			position: relative
			&:after
				+ad-flow-arrow
			&.is-none
				margin-bottom: 0

				&:after
					display: none

		&__label
			color: $color-txt-green
			text-align: center
			font-size: 15px
			+fwMedium
			line-height: 1
			margin-bottom: 0.5em

		&__body
			+fwLight
			font-size: 13px
			line-height: 1.46153846
		&__wide
			.ad-flow__body
				text-align: center
		+mq()
			width: auto
			margin: 0 auto px2vwSP(50) auto
			&__half
				width: px2vwSP(160)
				&.is-allow
					&:before
						top: px2vwSP(100)
						bottom: px2vwSP(20)
						left: calc(50% - #{px2vwSP(0.5)})
						+mq(600)
							top: px2vwSP(150)
						+mq(450)
							top: px2vwSP(175)
						+mq(400)
							top: px2vwSP(200)
					&:after
						+ad-flow-arrow
						bottom: px2vwSP(-4) !important
				.ad-flow__frame
					height: auto
			&__ttl
				font-size: 14px
			&__frame
				padding: px2vwSP(10)
				margin-bottom: px2vwSP(20)
			&__label
				font-size: 13px
			&__body
			&__wide

	.ad-dates
		display: flex
		width: 410px
		flex-wrap: wrap
		margin-bottom: 30px
		&__col
			width: 205px
		&__ttl
			font-size: 13px
			+fwMedium
			margin-bottom: 1em
		&__body
			font-size: 13px
			line-height: 2
		&__info
			width: 410px
			font-size: 13px
			line-height: 2
		+mq()
			width: auto
			display: block
			&__col
				width: auto
				margin-bottom: 1.5em
				&:last-child
					margin-bottom: 0
			&__ttl
				margin-bottom: 0.5em
			&__body
				line-height: 1.6
			&__info
				width: auto
				line-height: 1.5
			
	.ad-more
		padding: 20px
		+mq()
			font-feature-settings: "palt"
			padding: 0
			margin-right: -1em
			margin-top: 1.5em
			._body1
				line-height: 1.8

	+mq()	
		.ad-wrap
			margin-bottom: 0
		.ad-header
			margin-bottom: px2vwSP(25) !important
			position: relative
			&:after
				content: ''
				top: calc(50% - px2vwSP(3))
				right: px2vwSP(20)
				position: absolute
				width: 0
				height: 0
				border-width: 13px 10px
				border-style: solid
				border-color: $color-gold transparent transparent transparent
				//transition: all 100ms cubic-bezier(0.040, 0.510, 0.155, 0.985)
		.ad-contents
			opacity: 0
			height: 0
			pointer-events: none
			transition: all 500ms cubic-bezier(0.040, 0.510, 0.155, 0.985)
			transform: translate3d(0,-10px,0)
		.ad-wrap.is-open
			.ad-contents
				transition: all 1000ms cubic-bezier(0.040, 0.510, 0.155, 0.985)
				transform: none
				opacity: 1
				height: auto
				pointer-events: auto
			.ad-header:after
				transform: scale(1,-1)
				top: auto
				bottom: calc(50% - px2vwSP(3))
	